import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';
import { AssetService } from '../../../services/asset-service';
import { Observer } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { BorrowerVerificationOrderHistory } from '../../../models/borrower-verification-order-history.model';
import { BorrowerVerificationUserEnrollmentResponse } from '../../../models/borrower-verification-user-enrollment.model';

@Component({
  selector: 'app-assets-verification-dialog',
  templateUrl: './assets-verification-dialog.component.html',
  styleUrls: ['./assets-verification-dialog.component.scss']
})
export class AssetsVerificationDialogComponent implements OnInit {

  @Input() applicationId: number;
  @Input() task: ApplicationTaskModel;
  @Input() voaEnabled: boolean = false;

  protected provider: "FormFree" | "Truv" = "FormFree";
  protected thirdPartyEnrollmentWidget: string = "";

  constructor(public activeModal: NgbActiveModal,
    private _spinner: NgxSpinnerService,
    private _assetService: AssetService,
  ) {}

  ngOnInit(): void {
    const observer: Observer<BorrowerVerificationOrderHistory> = {
      next: (order: BorrowerVerificationOrderHistory) => {
        if (order) {
          this.provider = order.integrationProvider as "FormFree" | "Truv";
        }

        this.setEnrollmentWidget();
      },
      error: (err: any) => {
        if (err.status == 404) {
          this.setEnrollmentWidget();
        } else {
          console.log(err);
        }
      },
      complete: () => {
        
      }
    }

    this._spinner.show();
    this._assetService.getVerificationOrderHistoryForTask(this.task.taskId).subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  }

  setEnrollmentWidget(): void {
    // TODO: Need to find provider to use based on task
    this._assetService.initializeBorrowerAssetVerification(this.task.borrowerId, this.applicationId, this.provider).subscribe({
      next: (response: BorrowerVerificationUserEnrollmentResponse) => {
        this.thirdPartyEnrollmentWidget = response.htmlWidgetSrc;
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        console.log('complete');
      }
    });
  }
}
