import { ChannelRole } from "projects/borrower-app/src/app/models/channel-role.model";
import { ExternalContact } from "projects/borrower-app/src/app/models/external-contact.model";
import { InternalContact } from "projects/borrower-app/src/app/models/internal-contact.model";
import { Role } from "projects/borrower-app/src/app/models/role.model";
import { User } from "projects/borrower-app/src/app/models/user.model";
import { UserProfile } from "projects/borrower-app/src/app/models/user/user-profile.model";
import { Mentionable } from "./message-editor-with-mentions/mentionable.model";
import { EnumerationItem } from "./models/enumeration-item.model";

export class MentionsUtils {

  public static generateDisplayHtmlWithMentions = (displayText: string): string => {
    const regexp = new RegExp(
      /@\{(?<display>[\w ]+):(?<type>[\w ]+)(?::(?<key>[\w-])+)?\}/g
    );
    let results = regexp.exec(displayText);
    if (!results || results.length < 2) {
      return displayText;
    }

    const displayHtml = displayText.replace(
      results[0],
      `<a class='mention'>@${results[1]}</a>`
    );
    return MentionsUtils.generateDisplayHtmlWithMentions(displayHtml);
  };

  public static prepareUserMentionables = (apiBaseUrl: string, users: User[]) => {
    const usersThatCanBeMentioned = users.map((u) => {
      return MentionsUtils.toMentionable(apiBaseUrl, u);
    });
    return usersThatCanBeMentioned;
  };

  public static prepareInternalContactsMentionable = (): Mentionable => {
    const mentionable = {
      id: '@InternalContacts',
      name: 'Internal Contacts',
      avatarUrl: 'assets/images/male.png',
      isOnline: true,
      mentionCode: '@{Internal Contacts:InternalContacts}'
    };
    return mentionable;
  }

  public static prepareHereMentionable = (): Mentionable => {
    const mentionable = {
      id: '@Here',
      name: 'Here',
      avatarUrl: 'assets/images/male.png',
      isOnline: true,
      mentionCode: '@{Here:Here}'
    };
    return mentionable;
  }

  public static toMentionable = (apiBaseUrl: string, user: User | UserProfile): Mentionable => {
    const fullName = user.firstName + ' ' + user.lastName;
    const mentionable: Mentionable = {
      id: '@' + fullName,
      name: fullName,
      avatarUrl: user.avatarId
        ? apiBaseUrl + '/avatars/' + user.avatarId
        : 'assets/images/male.png',
      isOnline: true,
      mentionCode: `@{${fullName}:User:${user.userCompanyGuid}}`,
    };
    return mentionable;
  };

  public static convertToMentionable = (contact: ExternalContact): Mentionable => {
    const fullName = contact.firstName + ' ' + contact.lastName;
    const mentionable: Mentionable = {
      id: '@' + fullName,
      name: fullName,
      avatarUrl: 'assets/images/male.png',
      isOnline: true,
      mentionCode: `@{${fullName}:User:${contact.userId}}`,
    };
    return mentionable;
  };

  public static prepareMergeFieldsMentionables = (fields: EnumerationItem[]): Mentionable[] => {
    let mentionables: Mentionable[] = [];

    fields.forEach(f => {
      let mentionable = {
        id: '%' + f.value + '%',
        name: f.name,
        avatarUrl: 'assets/images/key.svg',
        isOnline: true,
        mentionCode: `%{${f.name}:Field:${f.value}}`,
      } as Mentionable;

      mentionables.push(mentionable)
    })

    return mentionables;
  };

  public static prepareContactRoleMentionables = (
    enabledChannel: string,
    channelRoles: any,
    internalContacts: InternalContact[],
    allUsers: User[]
  ): Mentionable[] => {
    let mentionables: Mentionable[] = [];

    let rolesAsMentionables: Role[] = [];
    if (enabledChannel) {
      rolesAsMentionables = channelRoles[enabledChannel.toLowerCase()];
    }

    rolesAsMentionables.forEach((role: ChannelRole) => {
      if (role.isLoanContact) {
        const mentionable = MentionsUtils.convertContactRoleToMentionable(role, allUsers, internalContacts);
        if (mentionable) {
          mentionables.push(mentionable);
        }
      }
    });
    return mentionables;
  };

  public static convertContactRoleToMentionable = (contactRole: Role, allUsers: User[], internalContacts: InternalContact[]): Mentionable => {
    const internalContactWithRole = internalContacts.find((c) => c.roleId === contactRole.roleId);
    if (internalContactWithRole) {
      const userAsInternalContact = allUsers.find((u) => u.userCompanyGuid === internalContactWithRole.userId);
      if (userAsInternalContact) {
        const fullName = (userAsInternalContact.firstName + ' ' + userAsInternalContact.lastName).trim();
        const mentionable: Mentionable = {
          id: '@' + fullName,
          name: contactRole.roleName,
          avatarUrl: 'assets/images/male.png',
          isOnline: true,
          mentionCode: `@{${fullName}:User:${userAsInternalContact.userCompanyGuid}}`,
        };
        return mentionable;
      }
    }
    return null;
  }
}
