
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import { DataService } from './data.service';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'auth-req': 'true', 'X-Requested-With': 'XMLHttpRequest' });

@Injectable()
export class BorrowerVerificationService {

  constructor(private readonly _dataService: DataService) {
  }

  public markBorrowerVerificationTaskComplete = (loanDocTaskId: number): Observable<any> => {
    const url = `online-app/borrower-verification/task/${loanDocTaskId}/mark-complete`;
    return this._dataService.post(url, {});
  }

  public markBorrowerVerificationTaskCompleteFake = (loanDocTaskId: number): Observable<any> => {
    // Simulate a delay of 3 seconds
    return of({}).pipe(delay(3000)) as Observable<void>;
  };
}
