import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationTaskModel } from '../../../models/borrower-portal/application-task-model';

@Component({
  selector: 'app-add-files-dialog',
  templateUrl: './add-files-dialog.component.html',
  styleUrls: ['./add-files-dialog.component.scss']
})
export class AddFilesDialogComponent implements OnInit {

  @Input() task: ApplicationTaskModel;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
