import { Component, Injector, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import TruvBridge, { SuccessMetadata } from '@truv/bridge';
import { firstValueFrom, forkJoin, Observable, Observer } from 'rxjs';
import { Borrower } from '../../../models/borrower-model';
import { BorrowerVerificationUserEnrollmentResponse } from '../../../models/borrower-verification-user-enrollment.model';
import { Employment } from '../../../models/employment-model';
import { TypeOfIncome } from '../../../models/enums/type-of-income.enum';
import { Income } from '../../../models/income-model';
import { IncomeService } from '../../../services/income-service';
import { BorrowerDigitalIncomeComponent } from '../borrower-digital-income/borrower-digital-income.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { DigitalIncomeVerificationStep } from '../../../models/wizard/digital-income-verification-step.model';
import { BorrowerVerificationService } from '../../../services/borrower-verification.service';

@Component({
  selector: 'digital-income-verification-step',
  templateUrl: 'digital-income-verification-step.component.html'
})
export class DigitalIncomeVerificationStepComponent extends WizardStepComponentBase<DigitalIncomeVerificationStep> implements OnInit, OnDestroy {

  @ViewChildren('borrowerDigitalIncome') borrowerIncomeComponents: QueryList<BorrowerDigitalIncomeComponent> | undefined;

  public allFieldsFilled: boolean = false;

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];

  voeEnabled: boolean = true;

  thirdPartyEnrollmentWidget: string;

  private _truvBridge: any;

  private _linkCreated: boolean = false;

  private _componentIsBeingDestroyed: boolean = false;

  private _loanDocTaskId: number | null | undefined;

  constructor(private readonly _injector: Injector,
    private readonly _incomeService: IncomeService,
    private readonly _borrowerVerificationService: BorrowerVerificationService
  ) {
    super(_injector);
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
        this.mainBorrower.primaryEmail.toLocaleLowerCase());
    }
    this.saveMortgageApplicationBeforeNextStep = true;
    if (!this.isEditEnabled) {
      this.voeEnabled = this.wizardFlowService.context.config.voeEnabled;
    }
  }

  onSpecifyIncomeAndEmploymentClicked = () => {
    this.wizardFlowService.navigateToManualEmploymentAndIncomeStep();
  }

  getBorrowersForDigitalIncomeCheck(): Borrower[] {
    let borrowers: Borrower[] = [];
    borrowers.push(this.mainBorrower);
    if (this.inCoApplyFlow) {
      return borrowers;
    }
    this.coBorrowers.forEach(borrower => {
      if (this.borrowerSetting(borrower)) {
        borrowers.push(borrower);
      }
    });
    return borrowers;
  }

  private allBorrowersDigitalIncomeChecked(): boolean {
    let borrowers = this.getBorrowersForDigitalIncomeCheck();
    for (let i = 0; i < borrowers.length; i++) {
      if (!borrowers[i].permissionToRunIncome) {
        return false;
      }
    }
    return true;
  }

  private onCheckClickedOnBorrowerCredit = (e: any): void => {
    this.allFieldsFilled = this.allBorrowersDigitalIncomeChecked();
    this.setNextButtonText();
  }

  private setNextButtonText = () => {
    if (this.allFieldsFilled) {
      this.nextButtonText = "Continue";
    } else {
      this.nextButtonText = "Continue without Digital Verification";
    }
  }

  private extractIncomeAndEmploymentData(borrower: Borrower, incomeAndEmploymentData: any) {
    let transactions: any[] = incomeAndEmploymentData.transactions;
    for (let transaction of transactions) {
      let digitallySourcedEmployment = this.extractEmploymentFromTransaction(transaction);
      this.extractEmploymentFromDigitallySourcedEmployment(borrower, digitallySourcedEmployment);
    }
  }

  private extractEmploymentFromTransaction(transaction: any): any {
    const employerCode = transaction.employer.employerCode;
    const employerName = transaction.employer.name;
    const years = Math.floor(transaction.employee.monthsInService / 12);
    const months = transaction.employee.monthsInService % 12;
    const position = transaction.employee.employmentPositionTitle;
    const incomes = transaction.annualCompensation;
    const baseComp = transaction.baseCompensation;

    const digitallySourcedEmployment = {
      employerWithStatus: `${employerName} Employer (${[1, 2, 3, 5, 6, 9, 10, 11, 13, 14, 17, 18, 19, 42, 43, 52, 53, 54, 58].includes(Number(transaction.employee.status.code)) ? 'Active' : 'Inactive'})`,
      originalHireDate: transaction.employee.originalHireDate,
      terminationDate: transaction.employee.terminationDate,
      address: transaction.employer.address,
      employmentStatus: [1, 2, 3, 5, 6, 9, 10, 11, 13, 14, 17, 18, 19, 42, 43, 52, 53, 54, 58].includes(Number(transaction.employee.status.code)) ? 'Active' : 'Inactive',
      position,
      employerCode,
      employerName,
      years,
      months,
      incomes,
      baseComp
    }
    return digitallySourcedEmployment;
  }

  private extractEmploymentFromDigitallySourcedEmployment(borrower: Borrower, digitallySourcedEmployment: any) {
    const employment = new Employment();
    // employment.employmentId = -1 * (new Date()).valueOf();
    employment.borrowerId = borrower.borrowerId;
    employment.addressId = -1 * (new Date()).valueOf();
    employment.employer = digitallySourcedEmployment.employerName;
    employment.employmentType = digitallySourcedEmployment.employmentStatus === 'Active' ? 'CurrentEmployer' : 'FormerEmployer';
    employment.position = digitallySourcedEmployment.position;
    employment.startDate = digitallySourcedEmployment.originalHireDate;
    employment.endDate = digitallySourcedEmployment.terminationDate;
    employment.yearsOnJob = digitallySourcedEmployment.years;
    employment.monthsOnJob = digitallySourcedEmployment.months;
    employment.isDigitallyVerified = true;
    employment.theWorkNumberEmployerCode = digitallySourcedEmployment.employerCode;
    employment.address = digitallySourcedEmployment.address;

    let incomes: any[] = digitallySourcedEmployment.incomes.sort((a: any, b: any) => (a.year > b.year) ? -1 : 1);
    for (let income of incomes) {
      if (income.base > 0) {
        let incomeObj: Income = new Income();
        incomeObj.borrowerId = employment.borrowerId;
        //incomeObj.companyId = employment.companyId;
        incomeObj.monthlyIncome = income.base / 12;
        incomeObj.typeOfIncome = TypeOfIncome.Base;
        employment.incomes.push(incomeObj);
        //check to see if this employment is already in the array
        let index = borrower.employments.findIndex(e => e.theWorkNumberEmployerCode === employment.theWorkNumberEmployerCode &&
          e.position === employment.position && e.monthlyIncome === employment.monthlyIncome);
        if (index === -1) {
          borrower.employments.push(employment);
        }
        //There should be only one base salary, just return. For some reason, there were multiple base
        //salaries coming from server.
        return;
      }
      /* We are only interested in base income
      if (income.bonus > 0) {
        let incomeObj: Income = new Income();
        incomeObj.borrowerId = employment.borrowerId;
        incomeObj.categoryOfIncome = CategoryOfIncome.Employment;
        incomeObj.monthlyIncome = income.bonus / 12;
        incomeObj.typeOfIncome = "3";
        employment.incomes.push(incomeObj);
      }
      if (income.commission > 0) {
        let incomeObj: Income = new Income();
        incomeObj.borrowerId = employment.borrowerId;
        incomeObj.categoryOfIncome = CategoryOfIncome.Employment;
        incomeObj.monthlyIncome = income.comission / 12;
        incomeObj.typeOfIncome = "4";
        employment.incomes.push(incomeObj);
      }
      if (income.other > 0) {
        let incomeObj: Income = new Income();
        incomeObj.borrowerId = employment.borrowerId;
        incomeObj.categoryOfIncome = CategoryOfIncome.Employment;
        incomeObj.monthlyIncome = income.other / 12;
        incomeObj.typeOfIncome = "9";
        employment.incomes.push(incomeObj);
      }
      if (income.overtime > 0) {
        let incomeObj: Income = new Income();
        incomeObj.borrowerId = employment.borrowerId;
        incomeObj.categoryOfIncome = CategoryOfIncome.Employment;
        incomeObj.monthlyIncome = income.overtime / 12;
        incomeObj.typeOfIncome = "10";
        employment.incomes.push(incomeObj);
      }
      */
    }
  }

  private initializeTruvBridge = () => {
    const self = this;
    setTimeout(() => {
      this._truvBridge = TruvBridge.init({
        bridgeToken: this.thirdPartyEnrollmentWidget,
        position: {
          type: 'inline',
          container: document.querySelector('#digital-incomes-order-container')
        },
        onLoad: () => {
          // Optional, called when Bridge loads
          console.log('Bridge loaded')
        },
        onSuccess: (public_token: string, metadata: SuccessMetadata) => {
          console.log('success handler')
          // Send the public_token to your server to exchange for an access_token
          // and retrieve payroll data.
          // The metadata object contains info about the Link.
          console.log("token: ", public_token)
          console.log("metadata: ", metadata)
        },
        onEvent: async (event_type: string, payload: any) => {
          // all events fire this function. event_type indicates what the event is,
          // payload has additional information depending on the event.
          if (event_type == "LINK_CREATED") {
            this._linkCreated = true;
          } else if (event_type == "CLOSE") {
            if (this._linkCreated) {
              await this.markVerificationAsComplete();
            }
          }
          console.log('event: ', event_type)
          console.log('payload: ', payload)

          if (event_type == "ERROR") {
            if (payload?.error?.error_type == 'SESSION_EXPIRED') {
              // Bridge token is expired
            }
          }
        },
        onClose: () => {
          // Optional, called when Bridge is closed by the user.
          console.log('Bridge closed');
          if (!self._componentIsBeingDestroyed) {
            super.onNextClicked();
          }
        }
      });

      this._truvBridge.open();
    }, 100);
  }

  private markVerificationAsComplete = async () => {
    this.startSpinner();
    try {
      await firstValueFrom(this._borrowerVerificationService.markBorrowerVerificationTaskCompleteFake(this._loanDocTaskId));
    } catch (error) {

    } finally {
      this.stopSpinner();
    }
  }

  private getTokenAndInitializeTruv = () => {
    this.startSpinner();
    const observer: Observer<BorrowerVerificationUserEnrollmentResponse> = {
      next: (response: any) => {
        this.thirdPartyEnrollmentWidget = response.htmlWidgetSrc;
        this._loanDocTaskId = response.loanDocTaskId;
        this.initializeTruvBridge();
      },
      error: (error: any) => {
        console.log(error);
      },
      complete: () => {
        console.log('complete');
      }
    }

    this._incomeService.initializeBorrowerIncomeAndEmploymentVerification(this.mainBorrower?.contactId, this.mortgageApplication.applicationId, this.step.vendorToUse).subscribe(observer)
      .add(() => {
        this.stopSpinner();
      });
  }

  onNextClicked() {
    if (!this.voeEnabled) {
      this.wizardFlowService.navigateToManualEmploymentAndIncomeStep();
      return;
    }
    if (this.allFieldsFilled) {
      if (this.wizardFlowService.isEditEnabled) {
        super.onNextClicked();
        return;
      }
      this.startSpinner();
      let apiCalls: Observable<any>[] = [];
      for (let borrower of this.getBorrowersForDigitalIncomeCheck()) {
        let apiCall = this._incomeService.verifyIncomeAndEmploymentData(this.mortgageApplication.applicationId,
          borrower.contactId!);
        apiCalls.push(apiCall);
      }
      if (apiCalls.length) {
        forkJoin(apiCalls).subscribe((results) => {
          for (let borrower of this.getBorrowersForDigitalIncomeCheck()) {
            const borrowerResult = results.find(r => r.borrowerId === borrower.contactId);
            if (borrowerResult) {
              this.extractIncomeAndEmploymentData(borrower, borrowerResult);
            }
          }
          this.stopSpinner();
          super.onNextClicked();
        }, error => {
          this.stopSpinner();
          //this.alert = new Alert("An error occurred digitally verifying income/employment.", error.error.message, AlertType.Error);
          super.onNextClicked();
        });
      } else {
        super.onNextClicked();
      }
    } else {
      super.onNextClicked();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.borrowerIncomeComponents) {
      for (let borrowerCredit of this.borrowerIncomeComponents) {
        borrowerCredit.checkStatusChanged.subscribe((e: any) => this.onCheckClickedOnBorrowerCredit(e));
      }
      this.allFieldsFilled = this.allBorrowersDigitalIncomeChecked();
      setTimeout(() => {
        this.setNextButtonText();
      })
    }
  }

  ngOnInit() {
    if (this.step.vendorToUse === 'Truv') {
      this.getTokenAndInitializeTruv();
    }
  }

  ngOnDestroy(): void {
    this._componentIsBeingDestroyed = true;
    this._truvBridge?.close();
  }
}
