import { Component, Input, OnInit } from '@angular/core';
import { PrequalDetailsModel } from '../../models/borrower-portal/prequal-details.model';
import { ApplicationForUser } from '../../models/borrower-portal/application-for-user-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneratePrequalLetterRequestModel } from '../../models/borrower-portal/generate-prequal-letter-request.model';
import { BasePortalService } from '../../services/base-portal.service';
import { Constants } from '../../services/constants';
import { NotificationService } from '../../services/notification.service';
import { MortgageCalculatorDialogComponent } from './mortgage-calculator-dialog/mortgage-calculator-dialog.component';
import { GeneratePrequalLetterDialogComponent } from './generate-prequal-letter-dialog/generate-prequal-letter-dialog.component';

@Component({
  selector: 'loan-actions',
  templateUrl: './loan-actions.component.html',
  styleUrls: ['./loan-actions.component.scss']
})
export class LoanActionsComponent implements OnInit {

  @Input() application: ApplicationForUser;

  prequalDetails: PrequalDetailsModel;
  hasPrequalDetails: boolean = false;

  constructor(
    private readonly _portalService: BasePortalService,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifsService: NotificationService) { }

  ngOnInit(): void {
    this.loadPrequalDetail(this.application.applicationId);
  }

  openGeneratePrequalLetterDialog = () => {
    const modalRef = this._modalService.open(GeneratePrequalLetterDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.details = this.prequalDetails;

    modalRef.result.then((req: GeneratePrequalLetterRequestModel) => {
      this.generatePrequalLetter(req);
    });
  }

  openMortgageCalculatorModal() {
    const modal = this._modalService.open(MortgageCalculatorDialogComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modal.componentInstance.application = this.application;
  }

  private generatePrequalLetter = (req: GeneratePrequalLetterRequestModel) => {
    this._spinnerService.show();

    req.loanAmount = req.purchasePrice - req.downPayment;
    this._portalService.generatePrequalLetterForApp(this.application.applicationId, req)
      .subscribe({
        next: (res) => {
          this._spinnerService.hide();
          const downloadFileGuid = res.docFileGuid;
          if (downloadFileGuid) {
            this.downloadFile(downloadFileGuid);
          }

        },
        error: (err) => {
          this._spinnerService.hide();
          const errorMessage = err?.message || "An error occurred generating prequalification letter.";
          this._notifsService.showError(errorMessage, "Error");
        }
      })

  }

  private downloadFile = (downloadFileGuid: string) => {
    this._spinnerService.show();
    this._portalService.downloadFile(downloadFileGuid)
      .subscribe({
        next: (res) => {
          this._spinnerService.hide();

          const blob = new Blob([res], { type: 'application/pdf' });
          let downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          let fileName = `PrequalificationLetter-${this.application.applicationId}.pdf`;
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        error: (err) => {
          console.error(err);
          this._spinnerService.hide();
        }
      })
  }

  private loadPrequalDetail = (appId: number) => {
    this._portalService.getPrequalDetailsForAppId(appId).subscribe(details => {
      this.prequalDetails = details;

      this.hasPrequalDetails = details && (details.downPaymentPercent > 0 ||
        details.maxPurchasePrice > 0 ||
        details.interestRate > 0);
    });
  }

}
