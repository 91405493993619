import { EventEmitter, Injectable, Type } from "@angular/core";
import { UserInfo } from "projects/shared/models/user-info.model";
import { Observable, Observer, Subject } from "rxjs";
import { filter, finalize, map } from "rxjs/operators";
import { BorrowerCreditScores } from "../../models/borrower-credit-scores.model";
import { WizardGroupEnum } from "../../models/enums/wizard-group-enum";
import { MortgageApplication } from "../../models/mortgage-app.model";
import { WizardFlowContext } from "../../models/wizard-flow-context.model";
import { WizardFlowCustomerPreferences } from "../../models/wizard-flow-customer-preferences.model";
import { AddressHistoryStep } from "../../models/wizard/address-history-step.model";
import { AddressStepFactory } from "../../models/wizard/config/factories/address-step.factory";
import { CreateAccountStepFactory } from "../../models/wizard/config/factories/create-account-step.factory";
import { CreditScoresStepFactory } from "../../models/wizard/config/factories/credit-scores-step.factory";
import { GenericStepFactory } from "../../models/wizard/config/factories/generic-step.factory";
import { MyInfoStepFactory } from "../../models/wizard/config/factories/my-info-step.factory";
import { NextStepDeciderFactory } from "../../models/wizard/config/factories/next-step-decider.factory";
import { SingleChoiceQuestionStepFactory } from "../../models/wizard/config/factories/single-choice-question-step.factory";
import { ICreateStep } from "../../models/wizard/config/factories/step-factory.interface";
import { FlowConfig } from "../../models/wizard/config/flow-config.model";
import { WizardFlowSettings } from "../../models/wizard/config/flow-settings.model";
import { StepType } from "../../models/wizard/config/step-type.enum";
import { WizardFlowConfig } from "../../models/wizard/config/wizard-flow-config.model";
import { ConsentAuthorizationStep } from "../../models/wizard/consent-authorization-step-model";
import { CreateCoborrowersStep } from "../../models/wizard/create-coborrowers-step.model";
import { CreditInquiryStep } from "../../models/wizard/credit-inquiry-step.model";
import { CurrentAddressStep } from "../../models/wizard/current-address-step.model";
import { DeclarationsStep } from "../../models/wizard/declarations-step.model";
import { DigitalIncomeVerificationStep } from "../../models/wizard/digital-income-verification-step.model";
import { NumberOfBorrowersStep } from "../../models/wizard/number-of-borrowers-step.model";
import { ReviewCreditStep } from "../../models/wizard/review-credit-step.model";
import { SelectOriginatorStep } from "../../models/wizard/select-originator-step.model";
import { SubmitApplicationStep } from "../../models/wizard/submit-application-step.model";
import { TypeOfLoanStep } from "../../models/wizard/type-of-loan-step.model";
import { WizardFlow } from "../../models/wizard/wizard-flow.model";
import { WizardNavGroup } from "../../models/wizard/wizard-nav-group.model";
import { WizardStepBase } from "../../models/wizard/wizard-step-base.model";
import { RegistrationComponent } from "../../registration/registration.component";
import { AuthService } from "../auth.service";
import { DataService } from "../data.service";
import { MortgageApplicationService } from "../mortgage-application.service";
import { NavigationService } from "../navigation.service";
import { UtilsService } from "../utils.service";
import { BrowserNavigationDeactivateGuard } from "./browser-navigation-deactivate-guard";
import { WizardFlowConfigServiceBase } from "./wizard-flow-config-service.base";
import { WizardFlowServiceBase } from "./wizard-flow-service.base";
import { Constants } from "../constants";
import { MortgageApplicationComponent } from "../../mortgage-application/mortgage-application.component";
import { CoMortgageApplicationComponent } from "../../co-mortgage-application/co-mortgage-application.component";
import { NavigationStart, Router } from "@angular/router";
import { MultiBorrowersAddressHistoryStep } from "../../models/wizard/multiborrowers-address-history-step.model";
import { BorrowerAppStatus } from "../../models/borrower-app-status.model";
import { BorrowerAppStatusDetails } from "../../models/borrower-app-status-details.model";
import { StepConfig } from "../../models/wizard/config/step-config.model";
import { WizardFlowConfigs } from "../../models/wizard/config/wizard-flow-config.model";
import { Borrower } from "../../models/borrower-model";
import { Declarations } from "../../models/declarations-model";
import { ResidencyAddress } from "../../models/residency-address.model";
import { Address } from "../../models/address-model";
import { CreditInquiryDetails, CreditInquiryDetailsEntry } from "../../models/credit-inquiry-details.model";
import { TransactionDetail } from "../../models/transaction-detail.model";
import { SubjectProperty } from "../../models/subject-property.model";
import { PurchaseCredit } from "../../models/purchase-credit.model";
import { Demographics } from "../../models/demographics-model";
import { LoanPurpose } from "../../models/enums/loan-purpose.enum";
import { FlowType } from "../../models/wizard/config/flow-type.enum";
import { CreditService } from "../credit.service";
import { Vendor } from "../../models/vendor.model";
import { ResidencyType } from "../../models/enums/residency-type.enum";
import { LoanCharacteristicsStep } from "../../models/wizard/loan-characteristics-step.model";
import { BorrowerCharacteristicsStep } from "../../models/wizard/borrower-characteristics-step.model";
import { MilitaryServiceStep } from "../../models/wizard/military-service-step.model";
import { ResidencyBasis } from "../../models/enums/residency-basis.enum";
import { HoiStep } from "../../models/wizard/hoi-step.model";
import { CharacteristicsService } from "../characteristics.service";
import { ChecklistsStepFactory } from "../../models/wizard/config/factories/checklists-step.factory";
import { MortgageTerm } from "../../models/mortgage-term.model";
import { VerifyCreditStepFactory } from "../../models/wizard/config/factories/verify-credit-step.factory";
import { IncomeStepFactory } from "../../models/wizard/config/factories/income-step.factory";
import { AssetsStepFactory } from "../../models/wizard/config/factories/assets-step.factory";
import { ReoStepFactory } from "../../models/wizard/config/factories/reo-step.factory";
import { OwnRentStepFactory } from "../../models/wizard/config/factories/own-rent-step.factory";
import { PurchaseCreditsStepFactory } from "../../models/wizard/config/factories/purchase-credits-step.factory";
import { ReviewApplicationStepFactory } from "../../models/wizard/config/factories/review-application-step.factory";
import { ApplicationForUser } from "../../models/borrower-portal/application-for-user-model";
import { Lead } from "../../models/lead.model";
import { MortgageService } from "../mortgage.service";
import { Occupancy } from "../../models/enums/occupancy.enum";
import { PullExistingApplicationStep } from "../../models/wizard/pull-existing-application-step.model";
import { LinkLiabilitiesToReoStep } from "../../models/wizard/link-liabilities-to-reo-step.model";
import { Utils } from "../utils";
import { PricingStepFactory } from "../../models/wizard/config/factories/pricing-step.factory";
import { DemographicsStepFactory } from "../../models/wizard/config/factories/demographics-step.factory";
import { GatherLeadSourceStepFactory } from "../../models/wizard/config/factories/gather-lead-source-step.fsctory";
import { HomeownershipEducationStep } from "../../models/wizard/homeownership-education-step.model";
import { DigitalAssetsStepFactory } from "../../models/wizard/config/factories/digital-assets-step.factory";
import { DigitalIncomeVerificationStepFactory } from "../../models/wizard/config/factories/digital-income-verification-step.factory";

const registerMainRoutePath: string = "register";
const applicationMainRoutePath: string = "apply";
const coApplicationMainRoutePath: string = "co-apply";

declare const CobrowseIO: any;

@Injectable()
export class WizardFlowService extends WizardFlowServiceBase {

  companyGuidIdentified: EventEmitter<string> = new EventEmitter<string>();

  inPreviewMode: boolean = false;

  private _loanInitializedEvent: Subject<ApplicationForUser> = new Subject<ApplicationForUser>();

  get loanInitializedEvent(): Subject<ApplicationForUser> {
    return this._loanInitializedEvent;
  }

  get activeFlowType(): FlowType {
    if (this._activeFlow.entryPath === registerMainRoutePath) {
      return FlowType.Register;
    } else if (this._activeFlow.entryPath === applicationMainRoutePath) {
      return FlowType.Apply;
    }
    return FlowType.CoApply;
  }

  get activeFlowConfig(): WizardFlowConfig | undefined {
    return this._activeFlowConfig;
  }

  get userInfo(): UserInfo | undefined {
    return this._userInfo;
  }

  get creditScores(): BorrowerCreditScores[] {
    return this._borrowerCreditScores;
  }

  set creditScores(borrowerCreditScores: BorrowerCreditScores[]) {
    this._borrowerCreditScores = borrowerCreditScores;
  }

  get isNavigationApplicable(): boolean {
    const isApplicable = this._activeFlow === this._mortgageApplicationFlow ||
      this._activeFlow === this._mortgageCoApplicationFlow;
    return isApplicable;
  }

  get isEditEnabled(): boolean {
    return this._token !== "";
  }

  get context(): WizardFlowContext {
    return this._wizardFlowContext;
  }

  get percentComplete(): number {
    if (this.inPreviewMode) {
      return 60;
    }
    if (!this._activeFlow) {
      return 0;
    }
    const numberOfSteps = this._activeFlow.steps.length;
    let currentStepIndex: number = 0;
    if (this._currentStep) {
      currentStepIndex = this._activeFlow.steps.indexOf(this._currentStep);
    }
    return Math.ceil(currentStepIndex / numberOfSteps * 100);
  }

  get isFirstStepOfRegistrationFlow(): boolean {
    if (this._activeFlow) {
      const activeFlowType = this.activeFlowType;
      if (activeFlowType === FlowType.Register) {
        if (this._currentStep) {
          const currentStepIndex = this._activeFlow.steps.indexOf(this._currentStep);
          return (currentStepIndex === 0);
        }
      }
    }
    return false;
  }

  private _registrationFlow!: WizardFlow;
  private _mortgageApplicationFlow!: WizardFlow;
  private _mortgageCoApplicationFlow!: WizardFlow;
  private _activeFlow!: WizardFlow;
  private _activeFlowConfig: WizardFlowConfig | undefined;

  private _currentStep!: WizardStepBase;
  private _previousStep: WizardStepBase | undefined;
  private _furthestVisitedStep!: WizardStepBase;

  private _visitedSteps: WizardStepBase[] = [];
  private _visitedGroups: number[] = [];

  private _lastBadFlowGuid: string | undefined;
  //private _companyGuid: string | undefined;
  private _branchGuid: string | undefined;
  //private _companyId: number | undefined;
  private _userGuid: string | undefined;
  //private _appId: string | undefined;
  private _token: string | undefined = "";
  private _campaignId: number | undefined;
  private _leadSourceOverride: string | undefined;
  private _referralSource: number | undefined;
  private _leadId: number | undefined;
  private _leadGuid: string | undefined;

  private _customerPreferences: WizardFlowCustomerPreferences;
  private _wizardFlowContext: WizardFlowContext;

  private _borrowerCreditScores: BorrowerCreditScores[] = [];

  private _flowConfigs: WizardFlowConfigs | undefined;
  private _registrationRoutesConfigured: boolean = false;
  private _applicationRoutesConfigured: boolean = false;
  private _coApplicationRoutesConfigured: boolean = false;

  private _userInfo: UserInfo | undefined;
  private _applicationInfo: any | undefined;
  private _stepConfigBeingPreviewed: StepConfig | undefined;

  private _isLoadingMortgage: boolean = false;

  constructor(private readonly _router: Router,
    private readonly _dataService: DataService,
    private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _utilsService: UtilsService,
    private readonly _navigationService: NavigationService,
    private readonly _wizardFlowConfigService: WizardFlowConfigServiceBase,
    private readonly _singleChoiceQuestionStepFactory: SingleChoiceQuestionStepFactory,
    private readonly _addressStepFactory: AddressStepFactory,
    private readonly _createAccountStepFactory: CreateAccountStepFactory,
    private readonly _nextStepDeciderFactory: NextStepDeciderFactory,
    private readonly _creditScoresStepFactory: CreditScoresStepFactory,
    private readonly _checklistsStepFactory: ChecklistsStepFactory,
    private readonly _myInfoStepFactory: MyInfoStepFactory,
    private readonly _verifyCreditStepFactory: VerifyCreditStepFactory,
    private readonly _incomeStepFactory: IncomeStepFactory,
    private readonly _assetsStepFactory: AssetsStepFactory,
    private readonly _ownRentStepFactory: OwnRentStepFactory,
    private readonly _purchaseCreditsStepFactory: PurchaseCreditsStepFactory,
    private readonly _reoStepFactory: ReoStepFactory,
    private readonly _reviewApplicationStepFactory: ReviewApplicationStepFactory,
    private readonly _pricingStepFactory: PricingStepFactory,
    private readonly _demoGraphicsStepFactory: DemographicsStepFactory,
    private readonly _gatherLeadSourceStepFactory: GatherLeadSourceStepFactory,
    private readonly _digitalAssetsStepFactory: DigitalAssetsStepFactory,
    private readonly _digitalIncomeVerificationStepFactory: DigitalIncomeVerificationStepFactory,
    private readonly _authService: AuthService,
    private readonly _creditService: CreditService,
    private readonly _characteristicsService: CharacteristicsService,
    private readonly _mortgageService: MortgageService) {
    super();

    this._branchGuid = this._utilsService.getUrlParameter("branchGuid");
    this._userGuid = this._utilsService.getUrlParameter("userGuid");
    //this._appId = this._utilsService.getUrlParameter("appId");
    this._customerPreferences = new WizardFlowCustomerPreferences();
    this._leadSourceOverride = this._utilsService.getUrlParameter("leadSourceOverride");
    const refSourceStr = this._utilsService.getUrlParameter("referralSource");
    if (!isNaN(parseInt(refSourceStr))) {
      this._referralSource = parseInt(refSourceStr);
    }
    const campaignId = this._utilsService.getUrlParameter("campaignId");
    if (campaignId) {
      this._campaignId = parseInt(campaignId);
    }
    const leadId = this._utilsService.getUrlParameter("leadId");
    if (leadId) {
      this._leadId = parseInt(leadId);
    }
    const leadGuid = this._utilsService.getUrlParameter("leadGuid");
    if (leadGuid) {
      this._leadGuid = leadGuid;
    }
    if (this.userGuid) {
      this._mortgageApplicationService.mortgageApplication.userGuid = this.userGuid;
    }
    this._token = this._utilsService.getUrlParameter("editToken");
    if (this._token && this._token !== "") {
      this._authService.setAdminToken(this._token);
    }
    this._wizardFlowContext = this.prepareEditModeWizardFlowContext();
    if (!this.isEditEnabled) {
      this._wizardFlowContext = new WizardFlowContext(this._mortgageApplicationService.mortgageApplication, this._customerPreferences);
    }
    this._wizardFlowContext.companyGuid = this._utilsService.getUrlParameter("companyGuid");
    if (this.userGuid && this.context.companyGuid) {
      this._mortgageApplicationService.getOriginator(this.companyGuid, this.userGuid).subscribe(originator => {
        this._wizardFlowContext.originator = originator;
      });
    }
    this._router.events.pipe(
      filter((event: any) => {
        return (event instanceof NavigationStart);
      }
      )
    ).subscribe((event) => {
      this.onNavigationStart(event);
    }
    );
  }

  getFlowConfigs = (): Observable<FlowConfig[]> => {
    return this._dataService.get('api/flow-builder?companyIdFilter=&flowTypeFilter=OnlineApplication', true);
  }

  deleteFlowConfig = (guid: string): Observable<any> => {
    return this._dataService.delete('api/flow-builder/by-guid/' + guid, true);
  }

  saveFlowConfigs = (settings: WizardFlowSettings): Promise<any> | undefined => {
    this._wizardFlowConfigService.flowConfigs.navigationType = settings.navigationType;
    this._wizardFlowConfigService.flowConfigs.hasProgressBar = settings.hasProgressBar;
    this._wizardFlowConfigService.flowConfigs.title = settings.title;
    this._wizardFlowConfigService.flowConfigs.comment = settings.comment;
    const payLoad = {
      flowType: 'OnlineApplication',
      flowGuid: this._wizardFlowConfigService.flowConfigs.guid,
      title: settings.title,
      comment: settings.comment,
      active: settings.isActive,
      isDefault: settings.isDefault,
      userCreationAutoConfirmed: this._wizardFlowConfigService.flowConfigs.userCreationAutoConfirmed,
      data: JSON.stringify(this._wizardFlowConfigService.flowConfigs)
    }
    let promise = new Promise((resolve, reject) => {
      this._dataService.put('api/flow-builder/by-guid/' + this._wizardFlowConfigService.flowConfigs.guid, payLoad, true)
        .toPromise()
        .then(
          response => {
            resolve(response);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  addNewStepToActiveFlow = (stepConfig: StepConfig) => {
    const factory = this.getStepFactory(stepConfig.stepType);
    const newStep = factory.create(stepConfig, this._wizardFlowContext);
    this._activeFlow.steps.push(newStep);
  }

  updateFlowConfig = (flowType: FlowType, step: WizardStepBase, settings: WizardFlowSettings): Observable<any> | undefined => {
    const stepConfig = this._wizardFlowConfigService.getConfig(flowType, step);
    if (stepConfig) {
      const factory = this.getStepFactory(stepConfig.stepType);
      if (factory) {
        factory.configure(step, stepConfig);
      }
    }

    switch (flowType) {
      case FlowType.Apply:
        this._wizardFlowConfigService.flowConfigs.applicationFlowConfig.stepGroups = settings.navGroups;
        break;
      case FlowType.CoApply:
        this._wizardFlowConfigService.flowConfigs.applicationFlowConfig.stepGroups = settings.navGroups;
        break;
    }

    this._wizardFlowConfigService.flowConfigs.navigationType = settings.navigationType;
    this._wizardFlowConfigService.flowConfigs.hasProgressBar = settings.hasProgressBar;
    this._wizardFlowConfigService.flowConfigs.title = settings.title;
    this._wizardFlowConfigService.flowConfigs.comment = settings.comment;
    const payLoad = {
      flowType: 'OnlineApplication',
      flowGuid: this._wizardFlowConfigService.flowConfigs.guid,
      title: settings.title,
      comment: settings.comment,
      active: settings.isActive,
      isDefault: settings.isDefault,
      userCreationAutoConfirmed: this._wizardFlowConfigService.flowConfigs.userCreationAutoConfirmed,
      data: JSON.stringify(this._wizardFlowConfigService.flowConfigs)
    }
    return this._dataService.put('api/flow-builder/by-guid/' + this._wizardFlowConfigService.flowConfigs.guid, payLoad, true);
  }

  createFlowConfig = (flowType: FlowType, step: WizardStepBase, settings: WizardFlowSettings): Observable<any> | undefined => {
    const stepConfig = this._wizardFlowConfigService.getConfig(flowType, step);
    if (stepConfig) {
      const factory = this.getStepFactory(stepConfig.stepType);
      if (factory) {
        factory.configure(step, stepConfig);
      }
    }
    this._wizardFlowConfigService.flowConfigs.navigationType = settings.navigationType;
    this._wizardFlowConfigService.flowConfigs.hasProgressBar = settings.hasProgressBar;
    this._wizardFlowConfigService.flowConfigs.title = settings.title;
    this._wizardFlowConfigService.flowConfigs.comment = settings.comment;
    const payLoad = {
      flowType: 'OnlineApplication',
      title: settings.title,
      comment: settings.comment,
      userCreationAutoConfirmed: this._wizardFlowConfigService.flowConfigs.userCreationAutoConfirmed,
      active: true,
      data: JSON.stringify(this._wizardFlowConfigService.flowConfigs)
    }
    return this._dataService.post('api/flow-builder', payLoad, true);
  }

  resetFlows = () => {
    this._applicationRoutesConfigured = false;
    this._registrationRoutesConfigured = false;
    this._coApplicationRoutesConfigured = false;
    this._mortgageApplicationService.resetMortgage();
  }

  getStepById<TStep extends WizardStepBase>(id: string): TStep | undefined {
    // There is really no type checking in type script, all types are erased in runtime
    // So - we cannot really enforce the type of the step here when we return.
    let flow: WizardFlow | undefined = this._activeFlow;
    if (!flow) {
      return undefined;
    }
    const step = <TStep>this._activeFlow.steps.find(s => s.id == id);
    return step;
  }

  getStep<TStep extends WizardStepBase>(path: string): TStep | undefined {
    // There is really no type checking in type script, all types are erased in runtime
    // So - we cannot really enforce the type of the step here when we return.
    let flow: WizardFlow | undefined = this._activeFlow;
    if (!flow) {
      flow = this.findFlowByPath(path);
    }
    if (!flow) {
      return undefined;
    }
    let step: TStep;
    if (!path || path.length === 0) {
      step = <TStep>flow.steps[0];
    } else {
      step = <TStep>flow.steps.find(s => s.path === path);
      if (!step) {
        step = <TStep>this._currentStep;
      }
    }
    return step;
  }

  isFirstStep(step: WizardStepBase): boolean {
    const firstStep = this._activeFlow.steps[0];
    return step === firstStep;
  }

  getCurrentStep(): WizardStepBase {
    return this._currentStep;
  }

  navigateToManualAssetsStep = (): void => {
    const step = this._activeFlow.steps.find(s => s.path === Constants.stepPaths.manualAssets);
    if (step) {
      return this.navigateToStep(step);
    }
  }

  navigateToManualEmploymentAndIncomeStep = (): void => {
    const step = this._activeFlow.steps.find(s => s.path === Constants.stepPaths.manualIncomeAndEmployment);
    if (step) {
      return this.navigateToStep(step);
    }
  }

  set stepConfigBeingPreviewed(stepConfig: StepConfig | undefined) {
    this._stepConfigBeingPreviewed = stepConfig;
    if (stepConfig) {
      let steps = this._activeFlow.steps.filter(s => s.id == stepConfig.id);
      if (steps && steps.length === 1) {
        this._currentStep = steps[0];
      } else {
        const step = this._activeFlow.steps.find(s => s.path.toLowerCase() === stepConfig.path.toLowerCase());
        if (step) {
          this._currentStep = step;
        }
      }
    }
  }

  get stepConfigBeingPreviewed(): StepConfig | undefined {
    return this._stepConfigBeingPreviewed;
  }

  get userGuid(): string | undefined {
    return this._userGuid;
  }

  get companyGuid(): string | undefined {
    return this.context.companyGuid;
  }

  get branchGuid(): string | undefined {
    return this._branchGuid;
  }

  get companyId(): number | undefined {
    return this.context.companyId;
  }

  get referralSource(): number | undefined {
    return this._referralSource;
  }

  get inCoApplyFlow(): boolean {
    if (!this._activeFlow)
      return false;
    return this._activeFlow.entryPath === coApplicationMainRoutePath;
  }

  saveMortgage = (): Observable<MortgageApplication> => {
    return this._mortgageApplicationService.saveMortgage().pipe(map(mortgage => {
      this.updateContext(mortgage);
      return mortgage;
    }))
  }

  navigateToApplicationFlow = (applicationId?: number) => {
    this.resetFlows();
    this._dataService.get('api/BorrowerPortal/GetApplicationsForUser').pipe(
      finalize(() => {
      })
    ).subscribe(
      response => {
        if (response.length > 0) {
          this._wizardFlowContext.applicationInfo = response[0];
        }
        this.doNavigateToApplicationFlow(response, applicationId);
      },
      error => {
        this.handleFlowStartError(error);
      }
    );
  }

  initializeFlowsInPreviewMode = (entryPath: string): Promise<WizardFlowConfigs | undefined> => {
    this.inPreviewMode = true;
    const flowGuid = this._utilsService.getUrlParameter(Constants.flowGuid);
    const promise = new Promise<WizardFlowConfigs | undefined>((resolve, reject) => {
      this.configureFlows(flowGuid, false, entryPath, true).then(() => {
        resolve(this._flowConfigs);
      });
    });
    return promise;
  }

  startFlow = (entryPath: string, stepPathToGoAfterStart?: string, reConfigure?: boolean) => {
    this.inPreviewMode = false;
    const flowGuid = this._utilsService.getUrlParameter(Constants.flowGuid);
    if ((this._applicationRoutesConfigured || this._registrationRoutesConfigured || this._coApplicationRoutesConfigured)
      && this._activeFlow.entryPath === entryPath &&
      (this._wizardFlowConfigService.flowConfigs.guid === flowGuid || this._lastBadFlowGuid === flowGuid)) {
      // If we have an active flow running, and the flow has not changed, no need to do anything
      if ((entryPath === Constants.flowEndpoints.apply || entryPath === Constants.flowEndpoints.coApply) && this._isLoadingMortgage) {
        return;
      }
      this.navigateToStepAfterStart(stepPathToGoAfterStart);
      return;
    }
    this.configureFlows(flowGuid, true, entryPath, reConfigure).then(() => {
      this.doStartFlow(entryPath, stepPathToGoAfterStart);
    });
  }

  updateContext = (mortgage: MortgageApplication) => {
    if (mortgage && mortgage.borrowers) {
      mortgage.borrowers.forEach(el => {
        if (!el.primaryEmail) {
          el.primaryEmail = '';
        }
        if (!el.declarations) {
          el.declarations = new Declarations();
        }
      });
    }
    this._wizardFlowContext.mortgage = mortgage;
    const loggedInBorrowerId = this._userInfo?.borrowerId;
    if (loggedInBorrowerId) {
      const currentBorrower = mortgage.borrowers.find(m => m.contactId === loggedInBorrowerId);
      if (currentBorrower) {
        this._wizardFlowContext.currentBorrower = currentBorrower;
      } else {
        this._wizardFlowContext.currentBorrower = mortgage.borrowers[0];
      }
    }
  }

  private doNavigateToApplicationFlow = (applications: any[], applicationId?: number) => {
    let applicationInfo: any = undefined;
    if (!applicationId) {
      applicationInfo = applications[0];
      /*
      if (applications.length > 1) {
        setTimeout(() => {
          // If there are more than one applications, re-redirect to the Borrower Portal - Loans, so that they can pick the app that they'd like to work on
          this._navigationService.redirectToBorrowerPortalLoans();
        }, 2000);
      } else {
        applicationInfo = applications[0];
      }
      */
    } else {
      applicationInfo = applications.find((a: any) => a.applicationId == applicationId);
    }
    if (!applicationInfo) {
      this._navigationService.navigateToPath(Constants.flowEndpoints.error);
      return;
    }
    this._applicationInfo = applicationInfo;
    this.context.companyId = applicationInfo.companyId;
    this._userInfo = new UserInfo(this._applicationInfo.myDetails.borrowerName, this._applicationInfo.myDetails.borrowerId,
      this._applicationInfo.myDetails.borrowerEmail);
    const flowEntryPath = this._applicationInfo.onlineApplicationIsCoborrower ? coApplicationMainRoutePath : applicationMainRoutePath;
    this.initializeCobrowse(applicationInfo.applicationId);
    this._navigationService.navigateToPath(flowEntryPath, true);
  }

  private registerAngularRoutesForFlows = (entryPath: string) => {
    if (entryPath === registerMainRoutePath) {
      this.registerRegistrationFlowRoutes();
    } else if (entryPath === applicationMainRoutePath || entryPath === 'checklists') {
      this.registerApplicationFlowRoutes();
    } else if (entryPath === coApplicationMainRoutePath) {
      this.registerCoApplicationFlowRoutes();
    }
  }

  private configureFlows = (flowGuid: string, useCompanyDefaultIfAny: boolean, entryPath: string, reConfigure?: boolean) => {
    const promise = new Promise((resolve, reject) => {
      if (!this._registrationFlow || !this._mortgageApplicationFlow || !this._mortgageCoApplicationFlow || reConfigure) {
        this.resetFlows();
        this._wizardFlowConfigService.configureFlows(flowGuid, useCompanyDefaultIfAny)?.subscribe(flowConfigs => {
          this._flowConfigs = flowConfigs;
          if (flowConfigs.isDefault) {
            // Config service gave us a default flow config - this means the flowGuid is bad. Need to remember it.
            this._lastBadFlowGuid = flowGuid;
          }
          this._registrationFlow = this.configureFlow(flowConfigs.registrationFlowConfig, registerMainRoutePath);
          this._mortgageApplicationFlow = this.configureFlow(flowConfigs.applicationFlowConfig, applicationMainRoutePath);
          this._mortgageCoApplicationFlow = this.configureFlow(flowConfigs.coApplicationFlowConfig, coApplicationMainRoutePath)
          this.registerAngularRoutesForFlows(entryPath);
          resolve('success');
        }, err => reject(err));
      } else {
        this.registerAngularRoutesForFlows(entryPath);
        resolve('success');
      }
    });
    return promise;
  }

  private startRegistrationFlow = (stepPathToGoAfterStart: string | undefined) => {
    if (this._leadGuid) {
      const observer: Observer<Lead> = {
        next: (lead: Lead) => {
          if (lead) {
            this.context.mortgage.subjectProperty.propertyWillBe = lead.propertyWillBe as Occupancy;
            this.context.mortgage.subjectProperty.projectType = lead.projectType;
            this.context.mortgage.subjectProperty.attachmentType = lead.attachmentType;
            this.context.mortgage.subjectProperty.address1 = lead.subjectPropertyAddress1;
            this.context.mortgage.subjectProperty.address2 = lead.subjectPropertyAddress2;
            this.context.mortgage.subjectProperty.city = lead.subjectPropertyCity;
            this.context.mortgage.subjectProperty.state = lead.subjectPropertyState;
            this.context.mortgage.subjectProperty.zipCode = lead.subjectPropertyZip;
            this.context.mortgage.borrowers[0].firstName = lead.firstName;
            this.context.mortgage.borrowers[0].lastName = lead.lastName;
            this.context.mortgage.borrowers[0].phoneNumber = Utils.removeCountryCodeIfAny(lead.phone);
            this.context.mortgage.borrowers[0].mobilePhone = Utils.removeCountryCodeIfAny(lead.phone);
            this.context.mortgage.borrowers[0].primaryEmail = lead.email;
            this.context.lead = lead;
          }
        },
        error: (error: any) => {
          console.error(error);
        },
        complete: () => {
        }
      }
      this._mortgageService.getLeadByGuid(this._leadGuid).subscribe(observer)
        .add(() => {
          this._currentStep = this.getStepToGoAfterRegistrationFlowStarts(stepPathToGoAfterStart);
          this.navigateToStep(this._currentStep);
          return;
        });
    } else {
      this._currentStep = this.getStepToGoAfterRegistrationFlowStarts(stepPathToGoAfterStart);
      this.navigateToStep(this._currentStep);
      return;
    }
  }

  private doStartFlow = (path: string, stepPathToGoAfterStart: string | undefined) => {
    if (path === registerMainRoutePath || this.isEditEnabled) {
      this.startRegistrationFlow(stepPathToGoAfterStart);
      return;
    }
    this._navigationService.navigateToPath("borrower-portal/loading");
    if (this._applicationInfo) {
      this.getMortgageAndNavigateToStep(this._applicationInfo, stepPathToGoAfterStart);
    } else {
      this._dataService.get('api/BorrowerPortal/GetApplicationsForUser').subscribe(
        response => {
          if (!response) {
            this._navigationService.navigateToPath("error");
            return;
          }
          this._characteristicsService.getTaskCategories().subscribe(categories => {
            if (categories && categories.length) {
              this._wizardFlowContext.thereAreLoanCharacteristics =
                categories.filter(c => c.characteristicType === "LoanCharacteristic").length > 0;
              this._wizardFlowContext.thereAreBorrowerCharacteristics =
                categories.filter(c => c.characteristicType === "BorrowerCharacteristic").length > 0;
            }
          });

          let selectedApp = response[0];
          if (this.context.applicationId) {
            const appWithGivenId = response.find(l => l.applicationId == this.context.applicationId);
            if (appWithGivenId) {
              selectedApp = appWithGivenId;
            }
          }

          if (!selectedApp) {
            this._navigationService.navigateToPath("error");
            return;
          }

          this.initializeCobrowse(selectedApp.applicationId);
          this._userInfo = new UserInfo(selectedApp.myDetails.borrowerName, selectedApp.myDetails.borrowerId, selectedApp.borrowerEmail);
          this.context.companyId = selectedApp.companyId;
          this._wizardFlowContext.applicationInfo = selectedApp;
          this.getMortgageAndNavigateToStep(selectedApp, stepPathToGoAfterStart);
        },
        error => {
          this.handleFlowStartError(error);
        }
      );
    }
  }

  private initializeCobrowse = (applicationId: number) => {
    const authDataJson = localStorage.getItem(Constants.authorization.authorizationDataKey);
    if (authDataJson) {
      const authData = JSON.parse(authDataJson);
      if (authData) {
        CobrowseIO.customData = {
          user_id: authData.userId,
          user_name: authData.userName,
          user_email: authData.userName,
          device_id: applicationId,
          device_name: authData.userName,
          application_id: applicationId
        };
      }
    }
  }

  private handleFlowStartError = (error: any) => {
    // TODO: Handle this with an http error interceptor
    this.resetFlows();
    if (error.status === 401) {
      this._navigationService.navigateToPath("login");
    } else {
      this._navigationService.navigateToPath("error");
    }
  }

  private getMortgageAndNavigateToStep = (applicationInfo: any, stepPathToGoAfterStart?: string) => {
    // App status needs to be pulled from the server here
    this._isLoadingMortgage = true;
    this._mortgageApplicationService.getMortgage(applicationInfo.applicationId).subscribe(mortgage => {
      this.updateContext(mortgage);
      this._isLoadingMortgage = false;
      this._mortgageApplicationService.getAppStatus(mortgage.applicationId, mortgage.mortgageId,
        this._wizardFlowContext!.currentBorrower!.borrowerId!).subscribe((status: any | undefined) => {

          let borrowerAppStatus = new BorrowerAppStatus();
          this._wizardFlowContext.borrowerAppStatus = borrowerAppStatus;
          if (status) {
            borrowerAppStatus.currentStatus = status.currentStatus;
            borrowerAppStatus.lastSuccessfulStatus = status.lastSuccessfulStatus;
            if (status.statusDataJson) {
              borrowerAppStatus.details = <BorrowerAppStatusDetails>JSON.parse(status.statusDataJson);
              if (!applicationInfo.onlineAppInProgress) {
                this._navigationService.navigateToPath("already-submitted");
                return;
              }
              if (borrowerAppStatus.details.coBorrowerIds && borrowerAppStatus.details.coBorrowerIds.length > 0) {
                borrowerAppStatus.details.coBorrowerIds.forEach(borrowerId => {
                  this._wizardFlowContext.borrowerSettings.delete(borrowerId);
                  this._wizardFlowContext.borrowerSettings.set(borrowerId, true);
                });
              }
              if (borrowerAppStatus.details.nonCoBorrowerIds && borrowerAppStatus.details.nonCoBorrowerIds.length > 0) {
                borrowerAppStatus.details.nonCoBorrowerIds.forEach(borrowerId => {
                  this._wizardFlowContext.borrowerSettings.delete(borrowerId);
                  this._wizardFlowContext.borrowerSettings.set(borrowerId, false);
                });
              }
            }
          }

          this._mortgageApplicationService.getConfig(applicationInfo.applicationId).subscribe(config => {
            this._wizardFlowContext.config = config;
            if (!config.defaultCreditVendor) {
              this._wizardFlowContext.skipAutomatedCreditCheck = true;
              this.navigateToStepAfterStart(stepPathToGoAfterStart);
              return;
            }
            this._creditService.getCreditVendorEnumItems().subscribe(allCreditvendors => {
              const defaultVendor = allCreditvendors.find(v => v.name == config.defaultCreditVendor);
              if (!defaultVendor) {
                this._wizardFlowContext.skipAutomatedCreditCheck = true;
                this.navigateToStepAfterStart(stepPathToGoAfterStart);
                return;
              }
              this._creditService.getVendors(mortgage.channel).subscribe((vendors: Vendor[]) => {
                const defaultVendorAmongApprovedVendors = vendors.find(v => v.vendorName === defaultVendor.name && v.active);
                if (!defaultVendorAmongApprovedVendors) {
                  // The automated credit run will be skipped, but we'll still get consent from the borrower at the verify credit step.
                  this._wizardFlowContext.skipAutomatedCreditCheck = true;
                }
                this.navigateToStepAfterStart(stepPathToGoAfterStart);
              });
            });
          });
        });
    }, error => {
      this._isLoadingMortgage = false;
    });
  }

  hasPreviousStep = (): boolean => {
    return this._visitedSteps && this._visitedSteps.length > 0;
  }

  getNavGroups(): WizardNavGroup[] {
    // Here you will return the nav groups
    const myInfoGroup = new WizardNavGroup(WizardGroupEnum.MyInfo, "My Info", "user-svg-icon", "");
    const myFinancesGroup = new WizardNavGroup(WizardGroupEnum.MyFinances, "My Finances", "", "fa fa-money-bill-alt");
    const myPropertyAndLoanGroup = new WizardNavGroup(WizardGroupEnum.PropertyAndLoan, "Property and Loan", "", "fas fa-home");
    const myDeclarationsGroup = new WizardNavGroup(WizardGroupEnum.Declarations, "Declarations", "", "far fa-file-alt");
    const myReviewAndSubmitGroup = new WizardNavGroup(WizardGroupEnum.ReviewAndSubmit, "Review and Submit", "", "fas fa-check-square");
    let navGroups: WizardNavGroup[] = [];
    navGroups.push(myInfoGroup);
    navGroups.push(myFinancesGroup);
    navGroups.push(myPropertyAndLoanGroup);
    navGroups.push(myDeclarationsGroup);
    navGroups.push(myReviewAndSubmitGroup);

    return navGroups;
  }

  navigateToFlow = (flowGuid: string | undefined) => {
    // The flow needs to be restarted
    this._navigationService.navigateToFlow(flowGuid, this._currentStep.path);
    this.startFlow(this._activeFlow.entryPath, this._currentStep.path, true);
  }

  navigateToFlowByType = (flowGuid: string) => {
    // The flow needs to be restarted
    this._navigationService.navigateToFlow(flowGuid, this._currentStep.path);
    this.startFlow(this._activeFlow.entryPath, this._currentStep.path, true);
  }

  navigateForward = (): void => {
    let nextStepPath: string | undefined = undefined;
    let nextStep: WizardStepBase | undefined = this._activeFlow.steps[0];
    if (this._currentStep) {
      nextStepPath = this._currentStep.nextStepPath();
      if (nextStepPath) {
        nextStep = this.getStep(nextStepPath);
      }
    }
    if (nextStep) {
      this.navigateToStep(nextStep);
    }
  }

  navigateBackward = (): void => {
    let previousStep = this._activeFlow.steps[0];
    let lastVisitedStep = this._visitedSteps.pop();

    if (lastVisitedStep) {
      previousStep = lastVisitedStep;
      this._previousStep = undefined;
      if (this._visitedSteps.length > 0)
        this._previousStep = this._visitedSteps[this._visitedSteps.length - 1];
    }
    this._currentStep = previousStep;
    this.updateApplicationStatus();
    setTimeout(() => {
      this.navigateToPath(previousStep.path, false, { backNavigation: true });
    }, 200);
  }

  navigateToGroup = (navGroup: WizardNavGroup): void => {
    //do not allow to go to a further group
    if (navGroup.id! >= this._currentStep.groupId!) {
      return;
    }
    //pop visited steps till you find next step
    let nextStep: WizardStepBase | undefined = this._activeFlow.steps.find(s => s.groupId === navGroup.id);
    if (nextStep && this._visitedGroups.includes(nextStep.groupId!)) {
      let step = this._visitedSteps.pop();
      while (step != nextStep) {
        step = (this._visitedSteps.pop());
      }
      this._currentStep = nextStep;
      this.navigateToStep(nextStep);
    }
  }

  isNavGroupCompleted(groupId: number): boolean {
    let groupSteps = this._activeFlow.steps.filter(i => i.groupId == groupId);
    if (!groupSteps || groupSteps.length == 0) {
      return false;
    }
    //each group step should be in visited steps;
    for (let j = 0; j < groupSteps.length; j++) {
      let groupVisited = this._visitedSteps.find(v => v.groupId == groupSteps[j].groupId);
      if (!groupVisited) {
        return false;
      }
    }
    return true;
  }

  private navigateToStepAfterStart = (stepPathToGoAfterStart?: string) => {
    const stepToGo = this.getStepToGoAfterApplicationFlowStarts(stepPathToGoAfterStart);
    this._currentStep = stepToGo;
    this.navigateToStep(this._currentStep, false);
    this._loanInitializedEvent.next(this._wizardFlowContext.applicationInfo);
  }

  private getStepToGoAfterRegistrationFlowStarts = (stepPath: string | undefined): WizardStepBase => {
    if (stepPath) {
      const initialStep = this.getStep(stepPath);
      if (initialStep) {
        return initialStep;
      }
    }
    return this._activeFlow.steps[0];
  }

  private getStepToGoAfterApplicationFlowStarts = (stepPath: string | undefined): WizardStepBase => {
    if (stepPath) {
      const initialStep = this.getStep(stepPath);
      if (initialStep) {
        return initialStep;
      }
    }
    this._visitedSteps = [];
    let borrowerAppStatus: BorrowerAppStatus | undefined = this._wizardFlowContext.borrowerAppStatus;
    if (borrowerAppStatus) {
      for (let i = 0; i < borrowerAppStatus.details.visitedStepIds.length; i++) {
        const step = this.getStepById(borrowerAppStatus.details.visitedStepIds[i]);
        if (step && !this._visitedSteps.includes(step)) {
          this._visitedSteps.push(step);
          if (this._visitedGroups.includes(step.groupId)) {
            this._visitedGroups.push(step.groupId!);
          }
        }
      }
      if (borrowerAppStatus.lastSuccessfulStatus) {
        let furthestVisitedStep = this.getStepById(borrowerAppStatus.lastSuccessfulStatus);
        if (furthestVisitedStep) {
          this._furthestVisitedStep = furthestVisitedStep;
        }
      }
      if (this._visitedSteps.length == 0) {
        //this._currentStep = this._activeFlow.steps[0];
        return this._activeFlow.steps[0];
      }
      if (borrowerAppStatus.currentStatus) {
        let currentStep = this.getStepById(borrowerAppStatus.currentStatus);
        if (currentStep) {
          return currentStep;
        }
      }
    }
    return this._activeFlow.steps[0];
  }

  private findFlowByPath = (path: string): WizardFlow | undefined => {
    if (!this._registrationFlow) {
      return undefined;
    }
    let step = this._registrationFlow.steps.find(s => s.path === path);
    if (step) {
      return this._registrationFlow;
    }
    step = this._mortgageApplicationFlow.steps.find(s => s.path === path);
    if (step) {
      return this._mortgageApplicationFlow;
    }
    step = this._mortgageCoApplicationFlow.steps.find(s => s.path === path);
    if (step) {
      return this._mortgageCoApplicationFlow;
    }
    return undefined;
  }

  private registerRegistrationFlowRoutes = () => {
    if (!this._registrationRoutesConfigured) {
      this.configureRoutesFromSteps(this._registrationFlow, RegistrationComponent);
      this._registrationRoutesConfigured = true;
    }
    this._activeFlow = this._registrationFlow;
    this._activeFlowConfig = this._flowConfigs?.registrationFlowConfig;
    this._activeFlowConfig.isDefault = this._flowConfigs?.isDefault;
  }

  private registerApplicationFlowRoutes = () => {
    if (!this._applicationRoutesConfigured) {
      this.configureRoutesFromSteps(this._mortgageApplicationFlow, MortgageApplicationComponent);
      this._applicationRoutesConfigured = true;
    }
    this._activeFlow = this._mortgageApplicationFlow;
    this._activeFlowConfig = this._flowConfigs?.applicationFlowConfig;
    this._activeFlowConfig.isDefault = this._flowConfigs?.isDefault;
  }

  private registerCoApplicationFlowRoutes = () => {
    if (!this._coApplicationRoutesConfigured) {
      this.configureRoutesFromSteps(this._mortgageCoApplicationFlow, CoMortgageApplicationComponent);
      this._coApplicationRoutesConfigured = true;
    }
    this._activeFlow = this._mortgageCoApplicationFlow;
    this._activeFlowConfig = this._flowConfigs?.coApplicationFlowConfig;
    this._activeFlowConfig.isDefault = this._flowConfigs?.isDefault;
  }

  private navigateToStep = (step: WizardStepBase, saveAppStatus: boolean = true): void => {
    //if going to a step, make sure to store the farthest visited step in
    //last step
    const stepIndex = this._activeFlow.steps.findIndex(s => s.id == step.id);
    const furthestVisitedStepIndex = this._activeFlow.steps.findIndex(s => s.id == this._furthestVisitedStep.id);
    if (stepIndex > furthestVisitedStepIndex) {
      this._furthestVisitedStep = step;
    }
    if (step !== this._currentStep) {
      step.previousStep = this._currentStep;
      this._previousStep = this._currentStep;
    }
    if (step !== this._currentStep && !this._visitedSteps.includes(this._currentStep)) {
      this._visitedSteps.push(this._currentStep);
    }
    if (!this._visitedGroups.includes(this._currentStep.groupId)) {
      this._visitedGroups.push(this._currentStep.groupId!);
    }

    this._currentStep = step;
    if (saveAppStatus) {
      this.updateApplicationStatus();
    }
    this.navigateToPath(step.path);
  }

  private updateApplicationStatus = (): void => {
    if (this._activeFlow !== this._registrationFlow && !this.isEditEnabled) {
      let borrowerAppStatus = this._wizardFlowContext.borrowerAppStatus;
      borrowerAppStatus.currentStatus = this._currentStep.id;
      borrowerAppStatus.lastSuccessfulStatus = this._furthestVisitedStep.id;
      borrowerAppStatus.details.visitedStepIds = this._visitedSteps.map(s => s.id);
      if (this._wizardFlowContext.mortgage.applicationId) {
        this._mortgageApplicationService.saveAppStatus(this._wizardFlowContext.mortgage.applicationId,
          this._wizardFlowContext.mortgage.mortgageId, this._wizardFlowContext!.currentBorrower!.borrowerId!, borrowerAppStatus)
          .subscribe(() => { });
      }
    }
  }

  private navigateToPath = (path: string, overrideUrl?: boolean, state?: any): void => {
    const pathToGo = this._activeFlow.entryPath + '/' + path;
    this._navigationService.navigateToPath(pathToGo, overrideUrl, state);
  }

  private configureRoutesFromSteps = (flow: WizardFlow, component: Type<any>) => {
    if (flow.steps.length === 0) {
      return;
    }

    let flowEntryRoute = this._router.config.find(r => r.component === component);
    if (flowEntryRoute) {
      const index = this._router.config.indexOf(flowEntryRoute);
      this._router.config.splice(index, 1);
    } else {
      flowEntryRoute = { path: flow.entryPath, component: component, children: [] };
    }

    flow.steps.forEach((step) => {
      flowEntryRoute?.children?.push({
        path: step.path,
        canDeactivate: [BrowserNavigationDeactivateGuard], component: step.component
      });
    });

    this._router.resetConfig([flowEntryRoute, ...this._router.config]);
  }

  private onNavigationStart = (event: any) => {
  }

  private configureFlow = (configToUse: WizardFlowConfig, entryPath: string): WizardFlow => {
    let flowToConfigure = new WizardFlow();
    flowToConfigure.entryPath = entryPath;

    configToUse.steps.forEach(stepConfig => {
      const stepFactory = this.getStepFactory(stepConfig.stepType);
      if (stepFactory) {
        const step = stepFactory.create(stepConfig, this._wizardFlowContext);
        flowToConfigure.steps.push(step);
      }
    });
    this._currentStep = flowToConfigure.steps[0];
    this._furthestVisitedStep = flowToConfigure.steps[0];
    return flowToConfigure;
  }

  getStepFactory = (stepType: StepType): ICreateStep | undefined => {
    if (stepType === StepType.SingleChoiceQuestionStep) {
      return this._singleChoiceQuestionStepFactory;
    } else if (stepType === StepType.AddressStep) {
      return this._addressStepFactory;
    } else if (stepType === StepType.CreateAccount) {
      return this._createAccountStepFactory;
    } else if (stepType === StepType.MyInfoStep) {
      return this._myInfoStepFactory;
    } else if (stepType === StepType.CreditScoresStep) {
      return this._creditScoresStepFactory;
    } else if (stepType === StepType.CheckListsStep) {
      return this._checklistsStepFactory;
    } else if (stepType === StepType.NumberOfBorrowersStep) {
      return new GenericStepFactory<NumberOfBorrowersStep>(this._nextStepDeciderFactory, NumberOfBorrowersStep);
    } else if (stepType === StepType.CreateCoBorrowersStep) {
      return new GenericStepFactory<CreateCoborrowersStep>(this._nextStepDeciderFactory, CreateCoborrowersStep);
    } else if (stepType === StepType.CurrentAddressStep) {
      return new GenericStepFactory<CurrentAddressStep>(this._nextStepDeciderFactory, CurrentAddressStep);
    } else if (stepType === StepType.OwnershipStatusStep) {
      return this._ownRentStepFactory;
    } else if (stepType === StepType.AddressHistoryStep) {
      return new GenericStepFactory<AddressHistoryStep>(this._nextStepDeciderFactory, AddressHistoryStep);
    } else if (stepType === StepType.MultiBorrowersAddressHistoryStep) {
      return new GenericStepFactory<MultiBorrowersAddressHistoryStep>(this._nextStepDeciderFactory, MultiBorrowersAddressHistoryStep);
    } else if (stepType === StepType.DigitalIncomeAndEmploymentStep) {
      return this._digitalIncomeVerificationStepFactory;
    } else if (stepType === StepType.ManualIncomeAndEmploymentStep) {
      return this._incomeStepFactory;
    } else if (stepType === StepType.ManualAssetsStep) {
      return this._assetsStepFactory;
    } else if (stepType === StepType.DigitalAssetsStep) {
      return this._digitalAssetsStepFactory;
    } else if (stepType === StepType.ReoStep) {
      return this._reoStepFactory;
    } else if (stepType === StepType.VerifyCreditStep) {
      return this._verifyCreditStepFactory;
    } else if (stepType === StepType.ReviewCreditStep) {
      return new GenericStepFactory<ReviewCreditStep>(this._nextStepDeciderFactory, ReviewCreditStep);
    } else if (stepType === StepType.CreditInquiryStep) {
      return new GenericStepFactory<CreditInquiryStep>(this._nextStepDeciderFactory, CreditInquiryStep);
    } else if (stepType === StepType.LoanTypeStep) {
      return new GenericStepFactory<TypeOfLoanStep>(this._nextStepDeciderFactory, TypeOfLoanStep);
    } else if (stepType === StepType.PullExistingApplicationStep) {
      return new GenericStepFactory<PullExistingApplicationStep>(this._nextStepDeciderFactory, PullExistingApplicationStep);
    } else if (stepType === StepType.DeclarationsStep) {
      return new GenericStepFactory<DeclarationsStep>(this._nextStepDeciderFactory, DeclarationsStep);
    } else if (stepType === StepType.DemographicsStep) {
      return this._demoGraphicsStepFactory;
    } else if (stepType === StepType.LoanCharacteristicsStep) {
      return new GenericStepFactory<LoanCharacteristicsStep>(this._nextStepDeciderFactory, LoanCharacteristicsStep);
    } else if (stepType === StepType.BorrowerCharacteristicsStep) {
      return new GenericStepFactory<BorrowerCharacteristicsStep>(this._nextStepDeciderFactory, BorrowerCharacteristicsStep);
    } else if (stepType === StepType.EConsentStep) {
      return new GenericStepFactory<ConsentAuthorizationStep>(this._nextStepDeciderFactory, ConsentAuthorizationStep);
    } else if (stepType === StepType.ReviewApplicationStep) {
      return this._reviewApplicationStepFactory;
    } else if (stepType === StepType.SubmitApplicationStep) {
      return new GenericStepFactory<SubmitApplicationStep>(this._nextStepDeciderFactory, SubmitApplicationStep);
    } else if (stepType === StepType.SelectOriginatorStep) {
      return new GenericStepFactory<SelectOriginatorStep>(this._nextStepDeciderFactory, SelectOriginatorStep);
    } else if (stepType === StepType.MilitaryServiceStep) {
      return new GenericStepFactory<MilitaryServiceStep>(this._nextStepDeciderFactory, MilitaryServiceStep);
    } else if (stepType === StepType.HoiStep) {
      return new GenericStepFactory<HoiStep>(this._nextStepDeciderFactory, HoiStep);
    } else if (stepType === StepType.PurchaseCreditsStep) {
      return this._purchaseCreditsStepFactory;
    } else if (stepType === StepType.GatherLeadSourceStep) {
      return this._gatherLeadSourceStepFactory;
    } else if (stepType === StepType.LinkLiabilitiesToReoStep) {
      return new GenericStepFactory<LinkLiabilitiesToReoStep>(this._nextStepDeciderFactory, LinkLiabilitiesToReoStep);
    } else if (stepType === StepType.PricingStep) {
      return this._pricingStepFactory;
    } else if (stepType === StepType.HomeownershipEducationStep) {
      return new GenericStepFactory<HomeownershipEducationStep>(this._nextStepDeciderFactory, HomeownershipEducationStep);
    }
    return undefined;
  }

  private prepareEditModeWizardFlowContext = (): WizardFlowContext => {
    let mortgageApplication = new MortgageApplication();
    mortgageApplication.userGuid = "unassigned";
    mortgageApplication.borrowers = [];
    let borrower = new Borrower();
    borrower.borrowerId = 12345;
    borrower.firstName = "Mark";
    borrower.lastName = "Spencer";
    borrower.socialSecNum = '666-66-6666';
    borrower.dateOfBirth = '01/01/1977';
    borrower.languagePreference = 'English';

    borrower.declarations = new Declarations();
    borrower.declarations.residenceStatus = "USCitizen";
    borrower.maritalStatus = "Single";
    borrower.phoneNumber = "(555)5555555";
    borrower.mobilePhone = "(555)5555555";

    borrower.residencyAddresses = [];
    let residencyAddress = new ResidencyAddress(ResidencyType.PresentAddress);
    residencyAddress.address = new Address();
    residencyAddress.address.address1 = "123 Any St.";
    residencyAddress.address.city = "San Diego";
    residencyAddress.address.state = "ca";
    residencyAddress.address.zipCode = "92128";

    residencyAddress.durationYears = 6;
    residencyAddress.durationMonths = 11;
    residencyAddress.rent = 3500;
    residencyAddress.residencyBasis = ResidencyBasis.Own;

    borrower.residencyAddresses.push(residencyAddress);

    let borrowerCreditScores: BorrowerCreditScores[] = [];
    let scores = new BorrowerCreditScores();
    scores.borrowerId = borrower.borrowerId;
    scores.borrowerName = "Mark Spencer";
    scores.equifax = 750;
    scores.experian = 678;
    scores.transUnion = 708;
    scores.date = new Date("01/01/2021");
    borrowerCreditScores.push(scores);
    this.creditScores = borrowerCreditScores;

    let creditInquiry = new CreditInquiryDetailsEntry()
    creditInquiry.newDebtAcquired = false;
    creditInquiry.fullName = "ABC Bank";
    creditInquiry.reason = "2";
    creditInquiry.creditInquiryDate = "01/01/2019";
    borrower.creditInquiry = new CreditInquiryDetails();
    borrower.creditInquiry.entries = [];
    borrower.creditInquiry.entries.push(creditInquiry);

    mortgageApplication.transactionDetail = new TransactionDetail();
    mortgageApplication.transactionDetail.purchasePriceAmount = 540000;
    let purchaseCredit = new PurchaseCredit();
    mortgageApplication.transactionDetail.purchaseCredits = [purchaseCredit];
    mortgageApplication.transactionDetail.purchaseCredits[0].purchaseCreditAmount = 200000;

    mortgageApplication.subjectProperty = new SubjectProperty();
    mortgageApplication.subjectProperty.downPaymentSource = "CashOnHand";
    mortgageApplication.subjectProperty.purposeOfLoan = LoanPurpose.Purchase;
    mortgageApplication.subjectProperty.address1 = '123 Any St.';
    mortgageApplication.subjectProperty.city = 'San Diego';
    mortgageApplication.subjectProperty.state = 'ca';
    mortgageApplication.subjectProperty.zipCode = '92128';
    mortgageApplication.subjectProperty.presentValue = 450000;

    mortgageApplication.mortgageTerm = new MortgageTerm();
    mortgageApplication.mortgageTerm.amount = 250000;
    mortgageApplication.mortgageTerm.mortgageAppliedFor = 'Conventional';

    mortgageApplication.extension.hasSignedPurchaseAgreement = false;

    borrower.declarations.haveCompletedShortSale = false;
    borrower.declarations.haveConveyedTitleInLieuOfForeclosure = false;
    borrower.declarations.haveJudgement = false;
    borrower.declarations.haveLawsuit = false;
    borrower.declarations.havePropertiesOwnership = false;
    borrower.declarations.haveRelationshipWithSeller = false;
    borrower.declarations.haveTaxDebt = false;
    borrower.declarations.occupyProperty = false;
    borrower.declarations.propertyHasLienWithCleanEnergyProgram = false;
    borrower.declarations.heldTitleHow = "0";
    borrower.declarations.borrowedDownPayment = false;
    borrower.declarations.coSignedLoan = false;
    borrower.declarations.beenForclosed = false;
    borrower.declarations.applyingForOtherMortgage = false;
    borrower.declarations.applyingForNonMortgageCredit = false;
    borrower.declarations.declaredBankruptcy = false;

    borrower.governmentMonitors = new Demographics();

    mortgageApplication.borrowers.push(borrower);

    mortgageApplication.realEstateOwned = [];
    let context = new WizardFlowContext(mortgageApplication, this._customerPreferences);
    return context;
  }

  get campaignId(): number | undefined {
    return this._campaignId;
  }

  get leadSourceOverride(): string | undefined {
    return this._leadSourceOverride;
  }

  get leadId(): number | undefined {
    return this._leadId;
  }

  get leadGuid(): string | undefined {
    return this._leadGuid;
  }
}
