import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PortalScope } from '../../login/login.component';
import { ApplicationForUser } from '../../models/borrower-portal/application-for-user-model';
import { ContactInfoModel } from '../../models/borrower-portal/contact-info-model';
import { BasePortalService } from '../../services/base-portal.service';
import { DrawerOptions, DrawerSize } from '../../services/drawer.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { NotificationService } from '../../services/notification.service';
import { EnvironmentService } from 'projects/shared/services/environment.service';

@Component({
  selector: 'loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss']
})
export class PortalLoanDetailsComponent implements OnInit {

  loanId: number;
  application: ApplicationForUser;

  agentRequestACallDrawerOptions: DrawerOptions = {
    size: DrawerSize.Large,
    containerWrapperId: null
  }

  borrowerContact: ContactInfoModel;

  agentAddressHidden: boolean = true;
  isAgent: boolean = false;

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _portalService: BasePortalService,
    private readonly _router: Router,
    private readonly _localStorage: LocalStorageService,
    private readonly _notifsService: NotificationService,
    private readonly _environment: EnvironmentService,
    private readonly _activatedRoute: ActivatedRoute) {

    let userScopeFromPath = null;
    if (this._activatedRoute.routeConfig && this._activatedRoute.routeConfig.data && this._activatedRoute.routeConfig.data.scope) {
      userScopeFromPath = this._activatedRoute.routeConfig.data.scope;
    }

    let userScopeFromLocalStorage = this._localStorage.authorizationData.scope as PortalScope;

    let userScope = this._environment.scope || userScopeFromPath || userScopeFromLocalStorage;

    if (userScope && userScope == PortalScope.Agent) {
      this.isAgent = true;
    }
  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.loanId = +params.get('loanId');
      this.loadApplicationDetails(this.loanId);
      this.loadBorrowerContactInfo(this.loanId);
    });
  }

  loadApplicationDetails = (appId: number) => {
    this._portalService.getLoanDataForAppId(appId).subscribe({
      next: (application) => {
        this.application = { ...application };
      }, error: (err) => {
        const errorMessage = err?.message || "An error occurred loading loan data";
        this._notifsService.showError(errorMessage, "Error");
      }
    })
  }

  loadBorrowerContactInfo = (appId: number) => {
    this._portalService.getContactInfoForApplication(appId).subscribe(borrowerContactInfo => {
      this.borrowerContact = borrowerContactInfo;
    });
  }

  openAgentContact = () => {
    this._router.navigate([`${this.isAgent ? 'agent' : 'borrower'}-portal/request-a-call`], { queryParams: { loanId: this.loanId } });
  }
}
