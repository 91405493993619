<div class="card mb-5 w-100" *ngIf="application">
  <div class="card-body pt-2 px-2 pb-4">
    <div class="d-flex flex-wrap flex-sm-nowrap d-revert">
      <!--begin: Pic-->
      <div class="ms-3 mt-3 me-3 mobile-picture-container">
        <div class="loan-item-icons" *ngIf="showViewTasksButton">
          <span class="position-relative "><i class="fa fa-check-circle fs-1 font-white"></i><span
              *ngIf="totals[application.applicationId]?.taskCount > 0"
              class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger fs-10-icon">
              {{
              badgeLabel(totals[application.applicationId]?.taskCount)
              }}
            </span></span>

          <span class="position-relative"><i class="fa fa-comment fs-1 font-white"></i><span *ngIf="
                totals[application.applicationId]?.messageCount > 0
              " class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger fs-10-icon">
              {{
              badgeLabel(
              totals[application.applicationId]?.messageCount
              )
              }}
            </span></span>
        </div>
        <div class="w-100 h-100 d-flex align-items-top justify-content-center address-image" (click)="goToDetails(application)">

          <loan-image [imageUrls]="[application.streetViewImageUrl]" [isSrcAllowed]="
              !portalContent?.isGoogleAddressesIntegrationDisabled
            " [size]="150">
          </loan-image>

        </div>
      </div>

      <div class="flex-grow-1">
        <div>
          <div>
            <div class="d-flex justify-content-between me-5 my-2 d-revert">
              <div>
                <a class="text-gray-900 fs-4 fw-bold me-1 text-hover-primary" href="javascript:void(0)" (click)="goToDetails(application)">
                  {{ subjectPropertyAddress }}
                </a>

                <div *ngIf="isAgent && showBorrowerContactDetails">
                  <ng-container *ngIf="application.otherBorrowersOnLoan?.length > 0">
                    <a *ngIf="application.otherBorrowersOnLoan[0].borrowerPhone" href="tel:{{ application.otherBorrowersOnLoan[0].borrowerPhone }}" class="me-6">
                      <i class="fa fa-phone"></i> {{ application.otherBorrowersOnLoan[0].borrowerPhone | phone }}
                    </a>
                    <a *ngIf="application.otherBorrowersOnLoan[0].borrowerEmail" href="mailto: {{ application.otherBorrowersOnLoan[0].borrowerEmail }}">
                      <i class="fa fa-envelope"></i> {{ application.otherBorrowersOnLoan[0].borrowerEmail }}
                    </a>
                  </ng-container>
                </div>

                <div class="d-flex align-items-center mt-2" *ngIf="!isAgent">
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="eConsentAuthorized{{application.applicationId}}"
                      name="eConsentAuthorized{{application.applicationId}}" [(ngModel)]="haveEConsent"
                      (ngModelChange)="onHaveEConsentChanged()">
                    <label class="form-check-label" for="eConsentAuthorized{{application.applicationId}}">
                      eConsent Authorized
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex align-items-center justify-content-end" *ngIf="showViewTasksButton">
                  <a href="javascript:void(0)" class="btn btn-sm btn-light me-2" *ngIf="application.onlineAppInProgress"
                    (click)="goToCompleteApplication(application)">
                    <span class="svg-icon svg-icon-3 d-none"><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3"
                          d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z"
                          fill="currentColor"></path>
                        <path
                          d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z"
                          fill="currentColor"></path>
                      </svg>
                    </span>
                    <span class="indicator-label"> Continue App</span>
                  </a>
                  <a href="javascript:void(0)" class="btn btn-sm bp-primary-bg-color"
                    *ngIf="!application.forceFinishOnlineApp" (click)="goToDetails(application)">Open</a>
                </div>

                <div class="d-flex align-items-center justify-content-end mt-2">
                  <span class="badge badge-light-success fs-base">
                    <i class="fs-5 text-success ms-n1"></i>
                    {{ application.loanStatus || "-" }}
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap fw-semibold fs-6 mb-1 pe-2 d-revert">
              <a href="javascript:void(0)" (click)="goToDetails(application)"
                class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 d-revert">
                {{ applicationTitle }}
              </a>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap flex-stack align-items-end d-revert">
          <div class="d-flex flex-column flex-grow-1 pe-8 d-revert">
            <div class="d-flex flex-wrap gap-2 d-revert">
              <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-xs-5">
                <div class="d-flex text-start align-items-center">
                  <i class="fas fa-check-circle text-info me-3"></i>
                  <div class="fs-4 fw-bold counted " data-kt-countup="true" data-kt-countup-value="60"
                    data-kt-countup-prefix="%" data-kt-initialized="1">
                    {{ (application?.refNumber || application?.applicationId) || "-" }}
                  </div>
                </div>
                <div class="fw-semibold fs-6 text-gray-400 text-start">
                  File No
                </div>
              </div>
              <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-xs-5">
                <div class="d-flex text-start align-items-center">
                  <i class="fas fa-user-circle text-success me-3"></i>
                  <div class="fs-4 fw-bold counted " data-kt-countup="true" data-kt-countup-value="75"
                    data-kt-initialized="1">
                    {{ application.loanPurpose || "-" }}
                  </div>
                </div>
                <div class="fw-semibold fs-6 text-gray-400 text-start">
                  Loan Purpose
                </div>
              </div>
              <div
                class="min-w-125px py-3 px-4 mb-xs-5"
                [ngClass]="!application.loanAmountHiddenOnPortals ? 'border border-gray-300 border-dashed rounded' : ''">
                <ng-container *ngIf="!application.loanAmountHiddenOnPortals" >
                  <div class="d-flex align-items-center me-3">
                    <i class="fas fa-money-bill-alt text-primary me-3"></i>
                    <div class="fs-4 fw-bold counted " data-kt-countup="true" data-kt-countup-value="4500"
                      data-kt-countup-prefix="$" data-kt-initialized="1">

                      <span *ngIf="!application.loanAmountHiddenOnPortals">
                        {{ application.loanAmount || 0 | currency }}</span><span
                        *ngIf="application.loanAmountHiddenOnPortals">--</span>
                    </div>
                  </div>
                  <div class="fw-semibold fs-6 text-gray-400 text-start">
                    Loan Amount
                  </div>
                </ng-container>
              </div>
              <div class="min-w-125px py-3 px-4 mb-xs-5"
                [ngClass]="!application.interestRateHiddenOnPortals ? 'border border-gray-300 border-dashed rounded' : ''">
                <ng-container *ngIf="!application.interestRateHiddenOnPortals">
                  <div class="d-flex align-items-center me-3">
                    <i class="fas fa-chart-line text-success me-3"></i>
                    <div class="fs-4 fw-bold counted" data-kt-countup="true" data-kt-countup-value="60"
                      data-kt-countup-prefix="%" data-kt-initialized="1">
                      <span *ngIf="!application.interestRateHiddenOnPortals">
                        {{ application.interestRate || 0 }} %</span><span
                        *ngIf="application.interestRateHiddenOnPortals">--</span>
                    </div>
                  </div>
                  <div class="fw-semibold fs-6 text-gray-400 text-start">
                    Interest Rate
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <agent-contact-info [agent]="agentContactInfo" (agentNameClicked)="onAgentNameClicked()"></agent-contact-info>
    </div>
  </div>
</div>
