import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'projects/shared/services/notification.service';
import TruvBridge, { SuccessMetadata } from '@truv/bridge';
import { BorrowerVerificationService } from '../../services/borrower-verification.service';
import { firstValueFrom } from 'rxjs';
import { IncomeService } from '../../services/income-service';

@Component({
  selector: 'digital-income-employment-verification',
  templateUrl: './digital-income-employment-verification.component.html',
  styleUrls: ['./digital-income-employment-verification.component.scss']
})
export class DigitalIncomeEmploymentVerificationComponent implements OnInit, OnDestroy {

  @Input() applicationId: number;
  @Input() borrowerId: number;

  @Input() taskId: number | null | undefined;

  @Input() provider: string = "Truv";
  @Input() thirdPartyEnrollmentWidget: string = "";
  
  @Output() complete: EventEmitter<any> = new EventEmitter<any>();
  @Output() error: EventEmitter<any> = new EventEmitter<any>();
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  private _truvBridge: any;

  private _componentIsBeingDestroyed: boolean = false;

  private _linkCreated: boolean = false;

  constructor(
    private readonly _incomeService: IncomeService,
    private readonly _borrowerVerificationService: BorrowerVerificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    if (this.provider == "FormFree") {
      this.initializeFormFree();
    } else if (this.provider == "Truv") {
      this.initializeTruvBridge();
    }
  }

  ngOnDestroy(): void {
    this._componentIsBeingDestroyed = true;
    this._truvBridge?.close();
  }

  private initializeFormFree = () => {
    let htmlWidgetSrc = this.thirdPartyEnrollmentWidget;
    htmlWidgetSrc = htmlWidgetSrc.replace(
      'element.parentNode.removeChild(element);',
      ''
    );

    document.getElementById("digital-income-employment-order-container").innerHTML = htmlWidgetSrc
    window.addEventListener('message', this.messageListener);
  }

  private initializeTruvBridge = () => {
    setTimeout(() => {
      this._truvBridge = TruvBridge.init({
        bridgeToken: this.thirdPartyEnrollmentWidget,
        position: {
          type: 'inline',
          container: document.querySelector('#digital-income-employment-order-container')
        },
        onLoad: () => {
          // Optional, called when Bridge loads
          console.log('Bridge loaded')
        },
        onSuccess: (public_token: string, metadata: SuccessMetadata) => {
          console.log('success handler')
          // Send the public_token to your server to exchange for an access_token
          // and retrieve payroll data.
          // The metadata object contains info about the Link.
          console.log("token: ", public_token)
          console.log("metadata: ", metadata)
        },
        onEvent: async (event_type: string, payload: any) => {
          // all events fire this function. event_type indicates what the event is,
          // payload has additional information depending on the event.
          if (event_type == "LINK_CREATED") {
            this._linkCreated = true;
          } else if (event_type == "CLOSE") {
            if (this._linkCreated) {
              await this.markVerificationAsComplete();
            }
          }
          console.log('event: ', event_type)
          console.log('payload: ', payload)

          if (event_type == "ERROR") {
            if (payload?.error?.error_type == 'SESSION_EXPIRED') {
              // Bridge token is expired
            }
          }
        },
        onClose: () => {
          // Optional, called when Bridge is closed by the user.
          console.log('Bridge closed');
          if (!this._componentIsBeingDestroyed) {
            this.close.emit();
          }
        }
      });

      this._truvBridge.open();
    }, 100);
  }

  private markVerificationAsComplete = async () => {
    try {
      if (this.taskId) {
        await firstValueFrom(this._borrowerVerificationService.markBorrowerVerificationTaskCompleteFake(this.taskId));
      } else {
        await firstValueFrom(this._incomeService.markFormFreeForBorrowerCompleteFake(this.borrowerId, this.applicationId));
      }
      this.complete.emit();
    } catch (error) {
      this._notificationService.showError(error.message || "An error occurred while verifying income/employment digitally.", "Error!");
      this.error.emit();
    } finally {
      this._spinner.hide();
    }
  }

  private messageListener = async (msg: any) => {
    if (msg.data === 'closeFrame') {
      await this.markVerificationAsComplete();
      
      window.removeEventListener('message', this.messageListener);
    }
  };
}
