
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import { DataService } from './data.service';
import { BorrowerVerificationUserEnrollmentRequest, BorrowerVerificationUserEnrollmentResponse } from '../models/borrower-verification-user-enrollment.model';
import { BorrowerVerificationOrderHistory } from '../models/borrower-verification-order-history.model';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'auth-req': 'true', 'X-Requested-With': 'XMLHttpRequest' });

@Injectable()
export class AssetService {

  constructor(private readonly _dataService: DataService) {
  }

  public markFormFreeForBorrowerComplete = (borrowerId: number, applicationId: number): Observable<any> => {
    const url = `api/online-app/MarkFormFreeForBorrowerComplete/${applicationId}/${borrowerId}`;
    return this._dataService.post(url, {});
  }

  public markFormFreeForBorrowerCompleteFake = (borrowerId: number, applicationId: number): Observable<any> => {
    // Simulate a delay of 3 seconds
    return of({}).pipe(delay(3000)) as Observable<void>;
  };

  public initializeFormFree = (borrowerId: number, applicationId: number, maxHeight: number = 600, maxWidth: number = 800): Observable<any> => {
    const url = `api/online-app/InitializeFormFreeForBorrower/${applicationId}/${borrowerId}?maxHeight=${maxHeight}&maxWidth=${maxWidth}`;
    return this._dataService.post(url, {});
  }

  public initializeBorrowerAssetVerification = (borrowerId: number, applicationId: number, integrationProvider): Observable<BorrowerVerificationUserEnrollmentResponse> => {
    const request: BorrowerVerificationUserEnrollmentRequest = {
      applicationId: applicationId,
      borrowerId: borrowerId,
      maxHeightInPixels: 600,
      maxWidthInPixels: 800,
      integrationProvider: integrationProvider,
      allowedProducts: ['Assets']
    };
    const url = `api/online-app/borrower-verification/invite`;
    return this._dataService.post(url, request);
  }

  public getVerificationOrderHistoryForTask = (loanDocTaskId: number): Observable<BorrowerVerificationOrderHistory> => {
    const url = `api/form-free/orders/task/${loanDocTaskId}`;
    return this._dataService.get(url);
  }
}
