import {ApplicationDetailModel} from "./application-detail-model";
import {OtherBorrowersOnLoanModel} from "./other-borrowers-on-loan-model";

export class ApplicationForUser {

  applicationId:number;
  refNumber: string | undefined;
  streetViewImageUrl: string | undefined;
  mapViewImageUrl: string | undefined;
  address: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  loanStatus: string;
  leadSource: string | undefined;
  blurb: string | undefined;
  loanPurpose: string | undefined;
  loanAmount:number;
  interestRate:number | undefined;
  contactRole: string | undefined;
  contactName: string | undefined;
  contactNumber: string | undefined;
  contactGuid: string | undefined;
  companyId:number | undefined;
  companyGuid: string | undefined;
  companyName: string | undefined;
  applicationName: string;
  onlineAppInProgress: boolean | undefined;
  forceFinishOnlineApp: boolean | undefined;
  onlineApplicationName: string | undefined;
  onlineApplicationVersion: string | undefined;
  onlineApplicationIsCoborrower: boolean | undefined;
  onlineAppRootUrl: string | undefined;
  loanAmountHiddenOnPortals: boolean | undefined;
  interestRateHiddenOnPortals: boolean | undefined;
  readonly contactCalendarLink: string | undefined;
  purchasePrice: number | undefined;

  myDetails: ApplicationDetailModel;
  otherBorrowersOnLoan: OtherBorrowersOnLoanModel[] | undefined;

  industryAffiliations: string | undefined;

  constructor() {
    this.applicationId = 0;
    this.loanAmount = 0;
    this.applicationName = '';
    this.loanStatus = '';

    this.myDetails = new ApplicationDetailModel();
  }
}
