<div class="card d-flex">
  <div class="card-header d-flex align-items-center justify-content-center" style="min-height: 50px;padding-top: 6px;">
    <h5 class="opacity-80"> Your Messages( {{ messageCount }} )</h5>
  </div>
  <div class="card-body flex-grow-1 p-1" style="height: 350px">
    <div class="d-flex align-items-center justify-content-center h-100" *ngIf="messageCount == 0">
      <span>No messages yet!</span>
    </div>
    <div *ngIf="messageCount > 0" class="h-100 px-2" style="overflow-x: hidden !important; overflow-y: auto !important"
      #scrollMe [scrollTop]="scrollMe.scrollHeight">
      <div class="w-100 my-2" *ngFor="let message of messages">
        <div *ngIf="userId != message.senderId" class="bubble-bottom-left me-auto">
          <div class="d-flex justify-content-start">

            <span class="py-3 px-4" [innerHTML]="message['mentionableContent']"></span>
          </div>
        </div>
        <div *ngIf="userId == message.senderId" class="bubble-bottom-right ms-auto">
          <div class="d-flex justify-content-end">
            <span class="py-3 px-4 me-auto" [innerHTML]="message['mentionableContent']"></span>

          </div>
        </div>

        <div class="d-flex my-4 px-3 justify-content-{{
            userId == message.senderId ? 'center' : 'center'
          }} text-muted">
          <small class="d-inline-block text-truncate" style="font-size: x-small !important">{{ message.postTime | date :
            "medium" }} &middot;
            {{ message.senderFirstName + " " + message.senderLastName }}</small>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer p-3">
    <message-editor-with-mentions #messageEditor [mentionables]="usersThatCanBeMentioned" (enterHit)="onEnterHit()"
      (messageChanged)="onMessageChanged($event)">
    </message-editor-with-mentions>

    <div class="d-flex align-items-center justify-content-end my-3">
      <a href="javascript:void(0)" class="btn btn-sm bp-primary-bg-color" (click)="postMessage()" *ngIf="!isSaving"><i
          class="fas fa-paper-plane bp-primary-bg-color"></i> Send
      </a>
      <div class="spinner-border thumb-lg text-primary" role="status" *ngIf="isSaving"></div>
    </div>
  </div>
</div>
