import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'projects/shared/shared.module';
import { SvgIconsModule } from 'projects/shared/svg-icons.module';
import { PortalAboutComponent } from './about/about.component';
import { AgentContactInfoComponent } from './agent-contact-info/agent-contact-info.component';
import { ConfirmESignerComponent } from './confirm-e-signer/confirm-e-signer.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { ESignViaTokenComponent } from './e-sign-via-token/e-sign-via-token.component';
import { EsignComponent } from "./e-sign/e-sign.component";
import { DndDirective } from "./file-upload/directives/dnd.directive";
import { FileListComponent } from "./file-upload/file-list/file-list.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { ProgressComponent } from "./file-upload/progress/progress.component";
import { GeneratePrequalLetterDialogComponent } from './loan-actions/generate-prequal-letter-dialog/generate-prequal-letter-dialog.component';
import { WizardAdditionalOptionsComponent } from './loan-actions/generate-prequal-letter-dialog/wizard-additional-options/wizard-additional-options.component';
import { PrequalLetterWizardDownPaymentComponent } from './loan-actions/generate-prequal-letter-dialog/wizard-down-payment/prequal-letter-wizard-down-payment.component';
import { PrequalLetterWizardGetStartedComponent } from './loan-actions/generate-prequal-letter-dialog/wizard-get-started/prequal-letter-wizard-get-started.component';
import { PrequalLetterWizardPropertyAddressComponent } from './loan-actions/generate-prequal-letter-dialog/wizard-property-address/prequal-letter-wizard-property-address.component';
import { PrequalLetterWizardPurchasePriceComponent } from './loan-actions/generate-prequal-letter-dialog/wizard-purchase-price/prequal-letter-wizard-purchase-price.component';
import { PrequalLetterWizardReviewInformationComponent } from './loan-actions/generate-prequal-letter-dialog/wizard-review-information/prequal-letter-wizard-review-information.component';
import { LoanActionsComponent } from './loan-actions/loan-actions.component';
import { LoanBorrowersComponent } from './loan-borrowers/loan-borrowers.component';
import { LoanContactsComponent } from './loan-contacts/loan-contacts.component';
import { PortalLoanDetailsComponent } from './loan-details/loan-details.component';
import { LoanInfoComponent } from './loan-info/loan-info.component';
import { LoanMessagesComponent } from './loan-messages/loan-messages.component';
import { PortalLoansComponent } from './loans/loans.component';
import { PortalReferAFriendComponent } from './refer-a-friend/refer-a-friend.component';
import { PortalRequestACallComponent } from './request-a-call/borrower-portal-request-a-call.component';
import { AddFilesDialogComponent } from './task-information/add-files-dialog/add-files-dialog.component';
import { AssetsVerificationDialogComponent } from './task-information/assets-verification-dialog/assets-verification-dialog.component';
import { AttachedDocumentsDialogComponent } from './task-information/attached-documents-dialog/attached-documents-dialog.component';
import { DocumentLockerTableComponent } from './task-information/document-locker-table/document-locker-table.component';
import { EConsentDialogComponent } from './task-information/e-consent-dialog/e-consent-dialog.component';
import { OtherTasksTableComponent } from './task-information/other-tasks-table/other-tasks-table.component';
import { OutstandingTableComponent } from './task-information/outstanding-table/outstanding-table.component';
import { PaymentInfoDialogComponent } from './task-information/payment-info-dialog/payment-info-dialog.component';
import { PaymentOrderDetailsComponent } from './task-information/payment-info-dialog/payment-order-details/payment-order-details.component';
import { ScenarioCardComponent } from './task-information/pinned-scenario-card/scenario-card.component';
import { ScenarioTaxesEditorComponent } from './task-information/pinned-scenario-card/scenario-taxes-editor/scenario-taxes-editor.component';
import { ScenariosComponent } from './task-information/scenarios/scenarios.component';
import { TaskAnswerQuestionDialogComponent } from './task-information/task-answer-question-dialog/task-answer-question-dialog.component';
import { PortalTaskInformationComponent } from './task-information/task-information.component';
import { TaskNotApplicableDialogComponent } from './task-information/task-not-applicable-dialog/task-not-applicable-dialog.component';
import { TasksAnswerQuestionComponent } from './task-information/tasks-answer-question/tasks-answer-question.component';
import { TasksAssetsComponent } from './task-information/tasks-assets/tasks-assets.component';
import { TasksHoiComponent } from './task-information/tasks-hoi/tasks-hoi.component';
import { TasksNormalComponent } from './task-information/tasks-normal/tasks-normal.component';
import { TasksOnlineAppComponent } from './task-information/tasks-online-app/tasks-online-app.component';
import { ViewDocumentComponent } from './task-information/view-document/view-document.component';
import { DigitalAssetsVerificationComponent } from './digital-assets-verification/digital-assets-verification.component';
import { DropdownModule } from 'primeng/dropdown';
import { MortgageCalculatorDialogComponent } from './loan-actions/mortgage-calculator-dialog/mortgage-calculator-dialog.component';
import { TasksIncomeAndEmploymentComponent } from './task-information/tasks-income-employment/tasks-income-employment.component';
import { IncomeEmploymentVerificationDialogComponent } from './task-information/income-employment-verification-dialog/income-employment-verification-dialog.component';
import { DigitalIncomeEmploymentVerificationComponent } from './digital-income-employment-verification/digital-income-employment-verification.component';

@NgModule({
  declarations: [
    AgentContactInfoComponent,
    PortalTaskInformationComponent,
    DocumentLockerTableComponent,
    OutstandingTableComponent,
    OtherTasksTableComponent,
    DndDirective,
    ProgressComponent,
    FileListComponent,
    FileUploadComponent,
    PortalLoansComponent,
    PortalLoanDetailsComponent,
    LoanBorrowersComponent,
    LoanContactsComponent,
    LoanMessagesComponent,
    LoanInfoComponent,
    GeneratePrequalLetterDialogComponent,
    PrequalLetterWizardGetStartedComponent,
    PrequalLetterWizardPropertyAddressComponent,
    PrequalLetterWizardPurchasePriceComponent,
    PrequalLetterWizardDownPaymentComponent,
    PrequalLetterWizardReviewInformationComponent,
    AttachedDocumentsDialogComponent,
    AddFilesDialogComponent,
    PaymentInfoDialogComponent,
    EConsentDialogComponent,
    ConfirmESignerComponent,
    PaymentOrderDetailsComponent,
    EsignComponent,
    ESignViaTokenComponent,
    ViewDocumentComponent,
    TasksOnlineAppComponent,
    TasksAssetsComponent,
    TasksIncomeAndEmploymentComponent,
    TasksHoiComponent,
    TasksNormalComponent,
    TasksAnswerQuestionComponent,
    AssetsVerificationDialogComponent,
    IncomeEmploymentVerificationDialogComponent,
    LoanContactsComponent,
    PortalRequestACallComponent,
    PortalAboutComponent,
    ConfirmRegistrationComponent,
    ScenariosComponent,
    ScenarioCardComponent,
    ScenarioTaxesEditorComponent,
    WizardAdditionalOptionsComponent,
    PortalReferAFriendComponent,
    TaskNotApplicableDialogComponent,
    TaskAnswerQuestionDialogComponent,
    LoanActionsComponent,
    MortgageCalculatorDialogComponent,
    DigitalAssetsVerificationComponent,
    DigitalIncomeEmploymentVerificationComponent
  ],
  imports: [
    SharedModule,
    NgbDropdownModule,
    NgApexchartsModule,
    CommonModule,
    FormsModule,
    SvgIconsModule,
    NgxMaskModule,
    NgbPopoverModule,
    DropdownModule
  ],
  exports: [
    DropdownModule,
    AgentContactInfoComponent,
    PortalTaskInformationComponent,
    DocumentLockerTableComponent,
    OutstandingTableComponent,
    OtherTasksTableComponent,
    DndDirective,
    ProgressComponent,
    FileListComponent,
    FileUploadComponent,
    PortalLoansComponent,
    PortalLoanDetailsComponent,
    LoanBorrowersComponent,
    LoanContactsComponent,
    LoanMessagesComponent,
    LoanInfoComponent,
    GeneratePrequalLetterDialogComponent,
    PrequalLetterWizardGetStartedComponent,
    PrequalLetterWizardPropertyAddressComponent,
    PrequalLetterWizardPurchasePriceComponent,
    PrequalLetterWizardDownPaymentComponent,
    PrequalLetterWizardReviewInformationComponent,
    AttachedDocumentsDialogComponent,
    AddFilesDialogComponent,
    PaymentInfoDialogComponent,
    EConsentDialogComponent,
    ConfirmESignerComponent,
    PaymentOrderDetailsComponent,
    EsignComponent,
    ESignViaTokenComponent,
    ViewDocumentComponent,
    TasksOnlineAppComponent,
    TasksAssetsComponent,
    TasksIncomeAndEmploymentComponent,
    TasksHoiComponent,
    TasksNormalComponent,
    TasksAnswerQuestionComponent,
    AssetsVerificationDialogComponent,
    IncomeEmploymentVerificationDialogComponent,
    LoanContactsComponent,
    PortalRequestACallComponent,
    PortalAboutComponent,
    ConfirmRegistrationComponent,
    ScenariosComponent,
    ScenarioCardComponent,
    ScenarioTaxesEditorComponent,
    PortalReferAFriendComponent,
    MortgageCalculatorDialogComponent,
    DigitalAssetsVerificationComponent,
    DigitalIncomeEmploymentVerificationComponent
  ]
})
export class PortalsCommonModule { }
