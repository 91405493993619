<div class="card">
  <div
    class="card-header d-flex align-items-center justify-content-center"
    style="min-height: 50px; padding-top: 6px"
  >
    <h5 class="opacity-80">Loan Actions</h5>
  </div>
  <div class="card-body">
    <div class="row">
      <a
        *ngIf="hasPrequalDetails"
        href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4 mb-3"
        (click)="openGeneratePrequalLetterDialog()"
        ><i class="text-white fa fa-file-alt fa-2x me-2"></i>Generate
        Preapproval Letter
      </a>

      <a
        href="javascript:void(0)"
        class="btn btn-sm bp-primary-bg-color py-4"
        (click)="openMortgageCalculatorModal()"
        ><i class="text-white fa fa-calculator fs-3 me-2 text-start"></i>
        Open Payment Calculator
      </a>
    </div>
  </div>
</div>
