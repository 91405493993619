import { EnumerationItem } from "projects/shared/models/enumeration-item.model";
import { Borrower } from "./borrower-model";
import { CustomData } from "./custom-data.model";
import { GlobalConfig } from "./global-config.model";
import { LoanApplication } from "./loan-info.model";
import { Profile } from "./profile.model";
import { ReferralSource } from "./referral-source.model";
import { UrlaMortgage } from "./urla-mortgage.model";
import { UserData } from "./user/user-data.model";
import { UserPermissions } from "./user/user-permissions.model";
import { UserType } from "./user/user-type.enum";

export class ApplicationContext {
  currentlyLoggedInUser: UserData;
  currentlyLoggedInUserProfile: Profile;
  application: LoanApplication;
  customData: CustomData;
  currentMortgage: UrlaMortgage;
  borrowers: Borrower[] = [];
  globalConfig: GlobalConfig;
  mortgageEnums: any;
  pricingEnums: any;
  userPermissions: UserPermissions;
  mergeFields: EnumerationItem[];
  industryAffiliations: string[] = [];

  isCallControlPanelOpen: boolean = false;

  get isTpo(): boolean {
    return this.currentlyLoggedInUser.userType === UserType.Tpo;
  }
}
