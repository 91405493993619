import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationTaskModel } from "../../../models/borrower-portal/application-task-model";
import { Constants } from "../../../services/constants";
import { AddFilesDialogComponent } from "../add-files-dialog/add-files-dialog.component";

@Component({
  selector: 'borrower-portal/outstanding-table',
  templateUrl: './outstanding-table.component.html'
})

export class OutstandingTableComponent implements OnInit {

  @Input() applicationTasks: ApplicationTaskModel[] = [];

  @ViewChild('uploadModal') uploadModal!: NgbModalRef;

  selectedTask: ApplicationTaskModel = new ApplicationTaskModel();
  uploadSuccess: string = 'Upload';

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  openAddFiles(task: ApplicationTaskModel) {
    const modalRef = this.modalService.open(AddFilesDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.task = task;

    modalRef.result.then(() => {
    });
  }

}
