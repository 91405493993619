import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationTaskModel } from "../../models/borrower-portal/application-task-model";
import { PortalServiceFactory } from "../../services/portal-service.factory";
import { PortalBaseComponent } from "../portal-base.component";

@Component({
  selector: 'borrower-portal/file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrls: ['file-upload.component.scss'],
})

export class FileUploadComponent extends PortalBaseComponent {

  files: any[] = [];
  isUploading = false;

  @Output() onUploadComplete = new EventEmitter();
  @Input() selectedTask: ApplicationTaskModel = new ApplicationTaskModel();

  constructor( private readonly portalServiceFactory: PortalServiceFactory) {
    super(portalServiceFactory);
  }

  onFileChoose($event: any) {
    let uploadedFiles = $event.target.files;

    for (const item of uploadedFiles) {
      item.progress = 0;
      this.files.push(item);
    }
  }

  uploadFiles() {
    this.files.forEach((file, index) => {
      this.portalService.uploadDocument(this.selectedTask.taskId, '', file).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.isUploading = true;
            file.progress = Math.round(100 * event.loaded / event.total);

            if(event.loaded === event.total) {
              this.isUploading = false;
              this.onUploadComplete.emit(true);
            }
          } else if (event instanceof HttpResponse) {
            console.log(event.body.message);
            this.isUploading = false;
          }
        },
        (err: any) => {
          console.log(err);
          file.progress = 0;
          this.isUploading = false;

          this.files = [];

          this.onUploadComplete.emit(false);
        });
    });
  }
}
